import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "../../Ui";
import { hexToRgba } from "../../../utils/utils";
import { keyframes } from "styled-components";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { respondTo } from "../../../theme/mixin";
import { lighten, darken } from "polished";

const BoxPromoContainer = styled.div`
	margin-bottom: 48px;
	${respondTo.sm`
        margin-bottom: 40px;
    `}
`;

const BoxPromoImage = styled.div`
	max-width: 573px;
	& img {
		clip-path: ${(props) =>
			props.theme.isrect
				? "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
				: "polygon(0 0, 100% 0, 100% 100%, 0 97%)"};
		display: block;
	}
	margin-bottom: 10px;
	cursor: pointer;
	position: relative;
	${respondTo.sm`
        margin-bottom: 16px;
    `}
`;

const BoxPromoImageOver = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: ${(props) => 
		props.theme.overlay
			? hexToRgba(props.theme.overlay,'0.85')
			: hexToRgba(props.theme.primary,'0.85') };
	filter: brightness(0.85);
	clip-path: ${(props) =>
		props.theme.isrect
			? "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
			: "polygon(0 0, 100% 0, 100% 100%, 0 97%)"};
	opacity: 0;
	transition: opacity 0.3s ease-out;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	&:hover {
		opacity: 1;
	}
`;

const moveFromDown = keyframes`
    from {
        opacity: 0;
        transform: translateY(25px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
`;

const DiscoverContainer = styled.div`
	&:hover {
		animation: ${moveFromDown} 1s linear 2s;
	}
`;

const BoxPromoText = styled.div`
	padding: 0;
	${respondTo.sm`
        padding: 0 1.375rem; /* 0 22px */
    `};
`;

const BscreamContainer = styled.div`
	margin-bottom: 6px;
	${respondTo.sm`
        margin-bottom: 11px;
    `}
`;

const BbodyContainer = styled.div``;

const LogoBox = styled.div`
	position: absolute;
	bottom: 10px;
	right: 10px;
	max-width: 100px;
`;

const LinkTag = styled(Link)`
	color: ${(props) => props.theme.btnTextColor};
	background-color: ${(props) =>
		props.bgcolor
			? props.bgcolor
			: props.secondary
			? props.theme.secondary
			: props.theme.primary};
	margin-top: 8px;
	display: inline-block;
	padding: 2px 6px;
	margin-right: 10px;
	text-transform: capitalize;
	font-size: 90%;
	&:hover {
		background-color: ${(props) =>
			props.bgcolor
				? lighten(0.02, props.bgcolor)
				: props.secondary
				? lighten(0.02, props.theme.secondary)
				: lighten(0.02, props.theme.primary)};
	}
`;

const LinkText = styled(Link)`
	color: ${(props) => props.theme.text.primary};
	&:hover {
		color: ${(props) => darken(0.8, props.theme.text.primary)};
	}
`;

const BoxPromo = (props) => {
	const {
		scream,
		description,
		imagelarge,
		logo,
		link,
		tags,
		istag,
		typosmall,
	} = props;

	const [t] = useTranslation();

	let tagsContent = null;
	let typo = "bscream";

	if (typosmall) {
		typo = "bscreamsmall";
	}

	// MS

	// if (tags.length > 0) {
	// 	tagsContent = tags.map((tag) => {
	// 		let slug = "";
	// 		if (tag.slug) {
	// 			slug = tag.slug;
	// 		}
	// 		return (
	// 			<LinkTag
	// 				key={tag.idtag}
	// 				to={{
	// 					pathname: `tags/${slug}`,
	// 					state: { cid: "box" },
	// 				}}
	// 			>
	// 				{tag.tag}
	// 			</LinkTag>
	// 		);
	// 	});
	// }

	if (tags.length > 0) {
		tagsContent = tags.map((tag) => {
			return (
				<LinkTag
					key={tag}
					to={{
						pathname: `tags/${tag}`,
						state: { cid: "box" },
					}}
				>
					{tag}
				</LinkTag>
			);
		});
	}

	return (
		<BoxPromoContainer>
			<Link
				to={{
					pathname: `/promo/${link}`,
					state: { cid: "box" },
				}}
			>
				<BoxPromoImage {...props}>
					<img src={imagelarge} />
					<BoxPromoImageOver {...props}>
						<DiscoverContainer>
							<Typography as="p" color="#fff" type="greatPrimer">
								{t("auth.discovermore")}
							</Typography>
						</DiscoverContainer>
					</BoxPromoImageOver>
					<LogoBox>
						<img src={logo} />
					</LogoBox>
				</BoxPromoImage>
			</Link>
			<BoxPromoText>
				<BscreamContainer>
					<LinkText
						to={{
							pathname: `/promo/${link}`,
							state: { cid: "boxtext" },
						}}
					>
						<Typography as="p" type={typo}>
							{scream}
						</Typography>
					</LinkText>
				</BscreamContainer>
				<BbodyContainer>
					<LinkText
						to={{
							pathname: `/promo/${link}`,
							state: { cid: "boxtext" },
						}}
					>
						<Typography
							as="p"
							type="bbody"
							dangerouslySetInnerHTML={{ __html: description }}
						>
							{/* {description} */}
						</Typography>
					</LinkText>
				</BbodyContainer>
				{istag ? "" : tagsContent}	{/* If not tags page, print tags section */}
			</BoxPromoText>
		</BoxPromoContainer>
	);
};

BoxPromo.propTypes = {
	scream: PropTypes.string,
	description: PropTypes.string,
	imagelarge: PropTypes.string,
	logo: PropTypes.string,
	link: PropTypes.string,
};

export default BoxPromo;
