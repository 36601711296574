import React from "react";
import PropTypes from "prop-types";
import { Typography } from "../../Ui";
import Stardust from "../Stardust";
import Facebook from "../Facebook";
import Google from "../Google";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
	padding: 12px 0;
	text-align: center;
`;

const ContainerUl = styled.ul`
	list-style-type: none;
`;

const ContainerLi = styled.li`
	margin-bottom: 8px;
`;

const CustomTypo = styled.div`
	margin-bottom: 12px;
`;

const MoreOauths = (props) => {
	const { oauths, oauthInfo, inModal, whereToGo } = props;
	const [t] = useTranslation();
	let content = null;

	content = oauths.map((o) => {
		switch (o) {
			case "stardust":
				return (
					<ContainerLi key={o}>
						<Stardust info={oauthInfo[o]} />
					</ContainerLi>
				);
			case "facebook":
				return (
					<ContainerLi key={o}>
						<Facebook {...props} />
					</ContainerLi>
				);
			case "google":
				return (
					<ContainerLi key={o}>
						<Google {...props} />
					</ContainerLi>
				);
		}
	});

	return (
		<Container>
			<CustomTypo>
				<Typography
					as="p"
					type="smallLink"
					fl
					align="center"
					secondary={!inModal}
				>
					{t("auth.orsigninwith")}
				</Typography>
			</CustomTypo>
			<ContainerUl>{content}</ContainerUl>
		</Container>
	);
};

MoreOauths.defaultProps = {};

MoreOauths.propTypes = {
	oauths: PropTypes.array,
	inModal: PropTypes.bool,
	oauthInfo: PropTypes.object,
};

export default MoreOauths;
