import * as actionTypes from './actionTypes';
import {
  getCmsFooter,
  getCmsAuthentication,
  getCmsProfile,
  getCmsContact,
  getCmsHowItWorks,
  getCmsHome,
  getCmsGame,
  getCmsMeccanica,
  getCmsAdvantages,
  getCmsCatalog,
  getCmsPremi,
  getCmsTutorial,
  getCmsHowToParticipate,
  getCmsMissions,
} from './cms';
import { checkAuth, checkAuthState } from './auth';
import { appInit } from './app';
import { getPartnerList } from './partners';
import { getAllProducts, getFiltersCategories, getFilterBrands, setIdCatalog } from './products';

import { getViewer } from './viewer';
import { getAllNews } from './news';

const bootAppStart = () => {
  return {
    type: actionTypes.BOOT_APP_START,
  };
};

const bootAppSuccess = () => {
  return {
    type: actionTypes.BOOT_APP_SUCCESS,
  };
};

const bootAppError = error => {
  return {
    type: actionTypes.BOOT_APP_ERROR,
    error,
  };
};

export const bootApp = () => {
  return dispatch => {
    dispatch(bootAppStart());
    Promise.all([
      dispatch(appInit()),
      dispatch(checkAuth()),
      dispatch(checkAuthState()),
      dispatch(getCmsFooter()),
      dispatch(getCmsPremi()),
      dispatch(getCmsAuthentication()),
      dispatch(getCmsProfile()),
      dispatch(getCmsContact()),
      dispatch(getCmsHome()),
      dispatch(getCmsMeccanica()),
      dispatch(getCmsCatalog()),
      dispatch(getCmsGame()),
      dispatch(getCmsAdvantages()),
      dispatch(getPartnerList()),
      dispatch(getViewer()),
      dispatch(getAllNews()),
      dispatch(getCmsTutorial()),
      dispatch(getCmsMissions()),
      dispatch(getCmsHowToParticipate()),
    ])
      .then(() => {
        dispatch(bootAppSuccess());
      })
      .catch(error => {
        dispatch(bootAppError(error.response.status));
      });
  };
};

export const bootCms = (loader = false) => {
  return dispatch => {
    if (loader) {
      dispatch(bootAppStart());
    }
    Promise.all([
      dispatch(getCmsHome()),
      dispatch(getCmsFooter()),
      dispatch(getCmsAuthentication()),
      dispatch(getCmsProfile()),
      dispatch(getCmsContact()),
      dispatch(getCmsMeccanica()),
      dispatch(getCmsCatalog()),
      dispatch(getCmsGame()),
      dispatch(getCmsAdvantages()),
      dispatch(getCmsTutorial()),
      dispatch(getCmsMissions()),
      dispatch(getCmsHowToParticipate()),
    ]).then(() => {
      if (loader) {
        dispatch(bootAppSuccess());
      }
    });
  };
};
