import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { respondTo } from '../../theme/mixin';

import { Text } from '../../components/Ui';
import AuthNav from '../../components/AuthNav/AuthNav';

const AuthContentWrapper = ({ title, children }) => {
  return (
    <AuthContent>
      <Text as='h5' bold size={16} style={{ marginTop: 0, marginBottom: 20 }} text_box>
        {title}
      </Text>
      {children}
    </AuthContent>
  );
};

const AuthContent = styled.div`
  margin-bottom: 30px;

  button {
    margin-top: 20px;
  }

  ${respondTo.sm`
    flex: 0 0 400px;

    .signInContainer {
      margin-bottom: 0;
    }
  `}
`;

AuthContentWrapper.propTypes = {
  title: PropTypes.string,
  navActive: PropTypes.string,
};

export default AuthContentWrapper;
