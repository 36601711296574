import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Override.css";
import Viewer from "../Viewer";

const CarouselViewer = (props) => {
	const { elements } = props;

	let content = null;

	let showInd = false;
	if (elements.length > 1) {
		showInd = true;
	}

	if (elements) {
		content = elements.map((el) => {
			return (
				<Viewer
					key={el.id}
					scream={el.i18l.title}
					description={el.i18l.content}
					imgdesktop={el.img_desktop}
					imgmobile={el.img_mobile}
					url={el.i18l.url}
					cta={el.i18l.calltoaction}
					ctatype={el.calltoaction_type}
					iscta={el.calltoaction}
					dark_text={el.dark_text}
				/>
			);
		});
	}

	return (
		<Carousel
			showThumbs={false}
			showArrows={false}
			showStatus={false}
			showIndicators={showInd}
		>
			{content}
		</Carousel>
	);
};

CarouselViewer.propTypes = {
	elements: PropTypes.array,
};

export default CarouselViewer;
