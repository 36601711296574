import React from 'react';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';

const Seo = props => {
  const { title, description, keywords, image } = props;
  const newTitle = title?.replace(/(<([^>]+)>)/gi, '');
  const newDesc = description?.replace(/(<([^>]+)>)/gi, '');

  const appName = useSelector(state => state.app.config.code);

  return (
    <Helmet>
      <meta name='description' content={newDesc || ''} />
      <meta name='keywords' content={keywords || ''} />
      <title>
        {appName && appName === 'BOFROSTANDFRIENDS'
          ? 'Parte il nuovo programma fedeltà con tantissimi premi'
          : newTitle || ''}
      </title>
      <meta property='og:type' content='website' />
      <meta property='og:title' content={newTitle || ''} />
      <meta property='og:description' content={newDesc || ''} />
      {appName && appName === 'BOFROSTANDFRIENDS' && (
        <meta
          name='description'
          content='bofrost&friends: il nuovo programma fedeltà bofrost* è una celebrazione del legame che ci unisce con ognuno dei nostri clienti.'
        />
      )}
      {image ? <meta property='og:image' content={image} /> : ''}
      {appName && appName === 'WURTH' && <meta name='robots' content='noindex, nofollow' />}
    </Helmet>
  );
};

export default Seo;
