import styled from 'styled-components';
import PropTypes from 'prop-types';
import { respondTo } from '../../../theme/mixin';

const Section = styled.section`
  width: 100%;
  margin-bottom: ${props => (props.marginBottomSmall ? '36px' : '45px')};
  margin-bottom: ${props => (props.marginBottomZero ? '0px' : '45px')};
  padding-top: ${props => (props.paddingTopSmall ? '36px' : '0')};
  ${respondTo.sm`
    margin-bottom: ${props => (props.marginBottomSmall ? '50px' : '90px')}; 
    margin-bottom: ${props => (props.marginBottomZero ? '0px' : '20px')}; 
    padding-top: ${props => (props.paddingTopSmall ? '50px' : '0')};
  `}
  ${respondTo.md`
    margin-bottom: ${props => (props.marginBottomSmall ? '50px' : '90px')}; 
    margin-bottom: ${props => (props.marginBottomZero ? '0px' : '30px')}; 
    padding-top: ${props => (props.paddingTopSmall ? '50px' : '0')};
  `}
`;

Section.propTypes = {
  marginBottomSmall: PropTypes.bool,
  marginBottomZero: PropTypes.bool,
};

export default Section;
