import * as actionTypes from "./actionTypes";

export const showInitialLoader = () => {
	return {
		type: actionTypes.SHOW_INITIAL_LOADER,
	};
};

export const hideInitialLoader = () => {
	return {
		type: actionTypes.HIDE_INITIAL_LOADER,
	};
};

export const showModal = () => {
	return {
		type: actionTypes.SHOW_MODAL,
	};
};

export const hideModal = () => {
	return {
		type: actionTypes.HIDE_MODAL,
	};
};

export const toggleModal = () => {
	return {
		type: actionTypes.TOGGLE_MODAL,
	};
};

export const toggleSidebar = (value) => {
	return {
		type: actionTypes.TOGGLE_SIDEBAR,
		value,
	};
};
