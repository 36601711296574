import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';

//import { ReactComponent as UserProfile } from '../../../assets/images/bxs-user-circle.svg';


const ProfileIconContainer = styled.div``;

const ProfileIconLink = styled.div`
    display: none;
    ${respondTo.sm`
        display: block;
    `}
`;

const ProfileIconSideBar = styled.div`
    display: block;
    ${respondTo.sm`
        display: none;
    `}
`;

const ProfileIcon = (props) => {
    
    const { toggleSidebar } = props;

    let profileIconLink = (
        <ProfileIconContainer>
            <ProfileIconLink>
                <Link to="/user/profile">
                    {/* <UserProfile />  */}
                </Link>
            </ProfileIconLink>
            <ProfileIconSideBar onClick={() => toggleSidebar()}>
                {/* <UserProfile /> */}
            </ProfileIconSideBar>
        </ProfileIconContainer>
    );

    return (
        <React.Fragment>
            { profileIconLink }
        </React.Fragment>
    )
}

ProfileIcon.propTypes = {
    toggleSidebar: PropTypes.func
}

export default ProfileIcon;