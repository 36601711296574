import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Page404 from './404';
import Page500 from './500';

const Container = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 100vh;
    ${props => props.internal && css`
        height: calc(100vh - 178px);
    `} 
`;

const NotFound = props => {

    const [t] = useTranslation();

    const { type, error } = props;

    let message = null;
    let page = null;

    switch(type) {
        case "app": 
            message = t('error.appnotfound');
        break;
        case "auth":
            if(error === 400) {
                message = t('error.autherror');
            } else {
                message = t('error.appnotfound');
            }
        break;
        case "promo": 
            message = t('error.promonotfound');
        break;
        default:
            message = t('error.pagenotfound');
        break;
    }

    if(error >= 500) {
        page = <Page500 errorCode={error} />
    } else {
        page = <Page404 message={message}  />
    }

    return (
        <Container {...props}>
            {page}
        </Container>
    )

}

NotFound.propTypes = {
    type: PropTypes.string
}

export default NotFound
