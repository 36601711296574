import React from 'react'
import PropTypes from 'prop-types';
import { Col } from 'react-awesome-styled-grid';
import { Text, Title } from '../../Ui';

const Columns = (props) => {
    
    const {
        perrow,
        children,
        type,
        content,
        style
    } = props;

    let ColumnContent = null;

    switch(type) {
        case 'text':
            ColumnContent = <Text {...style}>{content}</Text>
        break;
        case 'title':
            ColumnContent = <Title {...style}>{content}</Title>
        break;
        default:
            ColumnContent = children
        break;
    }


    switch(perrow) {
        case 3:
            return <Col xs={4} sm={6} md={4} lg={4}>{ColumnContent}</Col>
        case 2:
            return <Col xs={4} sm={6} md={6} lg={6}>{ColumnContent}</Col>
        default:
            return <Col xs={4} sm={12} md={12} lg={12}>{ColumnContent}</Col>
    }
}

Columns.propTypes = {
    perrow: PropTypes.number,
    children: PropTypes.any,
    type: PropTypes.string,
    content: PropTypes.any,
    style: PropTypes.object
}

export default Columns;
