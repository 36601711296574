import React from "react";
import styled from "styled-components";
import { respondTo } from "../../../theme/mixin";

const Container = styled.div`
	border-radius: 30px;
	background-color: ${(props) => props.theme.secondary};
	color: white;
	font-size: 24px;
	width: 120px;
	padding: 3px 0;
	margin: 0 auto 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
	${respondTo.sm`
		margin: 0 auto 20px;
		font-size: 32px;
	`}
`;

const SummaryGame = (props) => {
	const { right, total, text } = props;

	return <Container>{`${text} ${right}/${total}`}</Container>;
};

SummaryGame.defaultProps = {
	text: "",
};

export default SummaryGame;
