import React from "react";
import BoxPromo from "./BoxPromo/BoxPromo";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row } from "react-awesome-styled-grid";
import { Columns } from "../Ui";
import { useSpring, animated } from "react-spring";

const PromosContainer = styled.div``;

const ColumnsContainer = styled(Columns)`
	margin-bottom: 32px;
	background-color: red;
`;

const BoxPromos = (props) => {
	const { promos, perrow, istag } = props;

	const style = useSpring({ opacity: 1, from: { opacity: 0 } });

	let content = null;
	let typosmall = false;

	if (perrow >= 3) {
		typosmall = true;
	}

	if (promos.length !== 0) {
		content = promos.map((promo) => {
			return (
				<ColumnsContainer perrow={perrow} key={promo.id}>
					<BoxPromo
						scream={promo.i18l.title}
						link={promo.slug}
						description={promo.i18l.description}
						imagelarge={promo.img_box}
						logo={promo.logo}
						tags={promo.tags}
						istag={istag}
						typosmall={typosmall}
					/>
				</ColumnsContainer>
			);
		});
	}

	return (
		<React.Fragment>
			<animated.div style={style}>
				<PromosContainer>
					<Row>{content}</Row>
				</PromosContainer>
			</animated.div>
		</React.Fragment>
	);
};

BoxPromos.propTypes = {
	promos: PropTypes.array,
	perrow: PropTypes.number,
};

export default BoxPromos;
