import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { recoveryPasswordForm } from '../../formsConfig/formsConfig';
import useForm from '../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import { Button, Input, Loader, Modal, Flex } from '../Ui';
import ErrorInModal from '../ErrorInModal';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import * as actionCreators from '../../store/actions';
import AuthContentWrapper from '../AuthContentWrapper/AuthContentWrapper';

const RecoveryPasswordForm = props => {
  const history = useHistory();
  const inputRef = useRef();

  const { loading, recoveryPassword, error, clearError, recovery, recoverySuccessMessage } = props;

  const [showModal, setShowModal] = useState(false);

  const [t] = useTranslation();
  const recoveryForm = recoveryPasswordForm(t('auth.insertemail'), inputRef);

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (error !== null) {
      if (error) {
        setShowModal(true);
      }
    }
  }, [error]);

  const recoverySubmit = () => {
    recoveryPassword(formData.email.value);
  };
  const modalHandler = () => {
    setShowModal(false);
    clearError();
  };

  const { inputChangedHandler, formData, formSubmitHandler, firstSubmit, showError } = useForm(
    recoverySubmit,
    recoveryForm,
  );

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        icon={inp.icon}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        form={formData}
      />
    );
  });

  return (
    <>
      <form onSubmit={formSubmitHandler}>
        <AuthContentWrapper title='Inserisci la tua email.' navActive='login'>
          {inputs}
          <Flex justify='space-between' wrap='wrap'>
            <Button
              secondary
              upper
              disabled={loading}
              width='48%'
              onClick={() => history.push('/auth/login')}
            >
              Indietro
            </Button>
            <Button type='submit' upper active width='48%' loading={loading} disabled={loading}>
              {t('auth.resetemail')}
            </Button>
          </Flex>
        </AuthContentWrapper>
      </form>
      <Modal alert show={recovery} close={modalHandler}>
        {recoverySuccessMessage || t('auth.emailsentlabel')}
      </Modal>
      <Modal alert show={showModal} close={modalHandler}>
        <ErrorInModal error={error} />
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.auth.loadingRecovery,
    error: state.auth.errorRecovery,
    recovery: state.auth.recovery,
    recoverySuccessMessage: state.auth.recoverySuccessMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    recoveryPassword: email => dispatch(actionCreators.recoveryPassword(email)),
    clearError: () => dispatch(actionCreators.recoveryPasswordClearError()),
  };
};

RecoveryPasswordForm.propTypes = {
  loading: PropTypes.bool,
  recoveryPassword: PropTypes.func.isRequired,
  error: PropTypes.number,
  clearError: PropTypes.func,
  recovery: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPasswordForm);
