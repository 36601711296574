import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Link from "./Link";

const LinksContainer = styled.ul`
  list-style-type: none;
  text-align: center;
`;

const Links = props => {
  const { contents } = props;
  let links = null;
  if (contents && contents.length > 0) {
    links = contents.map(s => {
      return (
        <Link
          key={s.id}
          i18l={s.i18l}
          stile={s.style}
          type={s.type}
          isMobileVertical={contents.length >= 3 ? true : false}
        />
      );
    });
  }
  return <LinksContainer>{links}</LinksContainer>;
};

Links.propTypes = {
  contents: PropTypes.array
};

export default Links;
