import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from '../Ui';
import ErrorInModal from '../ErrorInModal';
import { authLogoutSuccess } from '../../store/actions/auth';

const SessionExpired = props => {
  const { isExpired, queryApp } = props;

  const dispatch = useDispatch();

  const closeModalHandler = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    if (isExpired && queryApp) {
      localStorage.clear();
      dispatch(authLogoutSuccess());
    }
  }, [queryApp, isExpired]);

  return queryApp ? (
    <></>
  ) : (
    <Modal alert show={isExpired} close={closeModalHandler}>
      <ErrorInModal error='sessionexpired' fromTranslation />
    </Modal>
  );
};

SessionExpired.propTypes = {
  isExpired: PropTypes.bool,
};

export default SessionExpired;
