import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';


const Favicon = props => {

    const { favicon } = props;

    return (
        <Helmet>
            <link rel="shortcut icon" href={favicon} />
        </Helmet>
    )
}

Favicon.propTypes = {
    favicon: PropTypes.string
}

export default Favicon;