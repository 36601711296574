import * as actionTypes from "../actions/actionTypes";

const initialState = {
	showInitialLoader: false,
	showModal: false,
	showSidebar: false,
};

const uiReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SHOW_INITIAL_LOADER:
			return {
				...state,
				showInitialLoader: true,
			};
		case actionTypes.HIDE_INITIAL_LOADER:
			return {
				...state,
				showInitialLoader: false,
			};
		case actionTypes.SHOW_MODAL:
			return {
				...state,
				showModal: true,
			};
		case actionTypes.HIDE_MODAL:
			return {
				...state,
				showModal: false,
			};
		case actionTypes.TOGGLE_MODAL:
			return {
				...state,
				showModal: !state.showModal,
			};
		case actionTypes.TOGGLE_SIDEBAR:
			return {
				...state,
				showSidebar: !state.showSidebar,
			};
		default:
			return state;
	}
};

export default uiReducer;
