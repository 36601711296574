import React from "react";
import PropTypes from "prop-types";
import Stardust from "../Stardust";

// In this component i menage the single one external component with external url (Ex Stardust play)

const SingleExternalOauth = props => {
  const { oauths, label, oauthInfo, small, isfullwidth } = props;

  let oa = null;

  if (oauths !== undefined) {
    if (oauthInfo !== null) {
      if (oauths.length > 0) {
        if (oauths.length === 1) {
          switch (oauths[0]) {
            case "stardust":
              oa = (
                <Stardust
                  isSingle
                  label={label}
                  info={oauthInfo[oauths[0]]}
                  small={small}
                  isfullwidth={isfullwidth}
                />
              );
              break;
          }
        }
      }
    }
  }

  return oa;
};

SingleExternalOauth.propTypes = {
  oauths: PropTypes.array,
  label: PropTypes.string,
  small: PropTypes.bool,
  isfullwidth: PropTypes.bool
};

export default SingleExternalOauth;
