import * as actionTypes from './actionTypes';
import customaxios from '../../config/axios-refresh-token';
import { sendLogError } from './error';
import { authLogoutSuccess, updateToken } from './auth';

const getUserInfoStart = () => {
  return {
    type: actionTypes.GET_USER_INFO_START,
  };
};
const getUserInfoSuccess = user => {
  return {
    type: actionTypes.GET_USER_INFO_SUCCESS,
    user,
  };
};
export const clearUserInfo = () => {
  return {
    type: actionTypes.CLEAR_USER_INFO,
  };
};
const getUserInfoError = error => {
  return {
    type: actionTypes.GET_USER_INFO_ERROR,
    error,
  };
};

export const getUserInfo = () => {
  let endpoint = 'auth/me';
  return dispatch => {
    dispatch(getUserInfoStart());

    return customaxios
      .get(endpoint)
      .then(result => {
        if (result.status === 200) {
          dispatch(getUserInfoSuccess(result.data));
          dispatch(updateToken());
        }
      })
      .catch(error => {
        dispatch(getUserInfoError(error));
        if (error.response.status === 400) {
          dispatch(authLogoutSuccess());
        }
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const setUserInfoStart = () => {
  return {
    type: actionTypes.SET_USER_INFO_START,
  };
};

export const setUserInfoSuccess = data => {
  return {
    type: actionTypes.SET_USER_INFO_SUCCESS,
    data,
  };
};

const setUserInfoError = error => {
  return {
    type: actionTypes.SET_USER_INFO_ERROR,
    error,
  };
};

export const setUserInfoClear = () => {
  return {
    type: actionTypes.SET_USER_INFO_CLEAR,
  };
};

export const setUserInfo = data => {
  let endpoint = 'auth/me';
  return dispatch => {
    dispatch(setUserInfoStart());

    return customaxios
      .post(endpoint, data)
      .then(result => {
        dispatch(setUserInfoSuccess(result.data.user));
      })
      .catch(error => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status === 400) {
          if (message === 'There is another user with the same email') {
            dispatch(setUserInfoError(2010));
          } else {
            dispatch(setUserInfoError(400));
          }
        } else {
          dispatch(setUserInfoError(message));
        }

        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const userActivationStart = () => {
  return {
    type: actionTypes.USER_ACTIVATION_START,
  };
};

const userActivationSuccess = message => {
  return {
    type: actionTypes.USER_ACTIVATION_SUCCESS,
    message,
  };
};

const userActivationError = error => {
  return {
    type: actionTypes.USER_ACTIVATION_ERROR,
    error,
  };
};

export const userActivationClearError = error => {
  return {
    type: actionTypes.USER_ACTIVATION_CLEAR_ERROR,
  };
};

export const userActivation = id => {
  let endpoint = `auth/signup/activate/${id}`;
  return dispatch => {
    dispatch(userActivationStart());
    customaxios
      .get(endpoint)
      .then(result => {
        dispatch(userActivationSuccess(result.data.status));
      })
      .catch(error => {
        dispatch(userActivationError(error.response.data.error));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const getUserIsLoggedStart = () => {
  return {
    type: actionTypes.GET_USER_IS_LOGGED_START,
  };
};

const getUserIsLoggedSuccess = userLogged => {
  return {
    type: actionTypes.GET_USER_IS_LOGGED_SUCCESS,
    userLogged,
  };
};

const getUserIsLoggedError = error => {
  return {
    type: actionTypes.GET_USER_IS_LOGGED_ERROR,
    error,
  };
};

export const getUserIsLogged = () => {
  let endpoint = `auth/check`;
  return dispatch => {
    dispatch(getUserIsLoggedStart());
    customaxios
      .get(endpoint)
      .then(result => {
        dispatch(getUserIsLoggedSuccess(result));
      })
      .catch(error => {
        dispatch(getUserIsLoggedError(error));
        if (error.response.status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};

const changePasswordStart = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_START,
  };
};

const changePasswordSuccess = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
  };
};

export const changePasswordClear = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_CLEAR,
  };
};

const changePasswordError = error => {
  return {
    type: actionTypes.CHANGE_PASSWORD_ERROR,
    error,
  };
};

export const changePasswordClearError = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_CLEAR_ERROR,
  };
};

export const changePassword = (password, confirm) => {
  let endpoint = `auth/me/changepassword`;
  return dispatch => {
    dispatch(changePasswordStart());
    customaxios
      .post(endpoint, {
        password,
        confirm,
      })
      .then(result => {
        dispatch(changePasswordSuccess());
      })
      .catch(error => {
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status === 400) {
          if (message === 'User not related to this application') {
            dispatch(changePasswordError(2020));
          } else {
            dispatch(changePasswordError(2050));
          }
        }
        if (status === 422) {
          if (message === 'Password and confirm password are not the same') {
            dispatch(changePasswordError(2030));
          }
        }
        if (status >= 500) {
          dispatch(sendLogError(endpoint, error));
        }
      });
  };
};
