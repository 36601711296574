import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import Fade from '../../components/Fade';
import SliderProductContinue from '../../components/sliderProductContinue';
import Notification from '../../components/HomeBlocks/Notification';
import { Loader, Modal, Text } from '../../components/Ui';
import { closeOrderModal } from '../../store/actions/products';
import { addToCartClearError } from '../../store/actions/wishlist';
import Recensione from '../../components/recensione';
import ProductDetails from '../../components/product/productDetails';
import ReactShadowScroll from 'react-shadow-scroll';

const Container = styled.div`
  ${respondTo.sm`
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
  `}
`;

const Premi = props => {
  const [isReviews, setIsReviews] = useState(false);
  const [puntiAbbastanza, setpuntiAbbastanza] = useState(false);
  const cmsPremi = useSelector(state => state.cms.premi);
  const blocks = cmsPremi?.[0]?.contents;
  const {
    product,
    orderModal,
    loading: singleProductLoading,
  } = useSelector(state => state.product);
  const error = useSelector(state => state.user.cartError);
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  return (
    <>
      <Fade>
        <Container>
          {blocks?.map((item, i, arr) => {
            const content =
              item.name !== 'title_Home' ? (
                <>
                  {item.type === 'catalog' ? (
                    <SliderProductContinue
                      setIsReviews={setIsReviews}
                      data={cmsPremi[0].contents.length > 0 ? cmsPremi[0].contents : []}
                      index={i}
                      list={item.products}
                      title={item.i18l.title}
                      width={item.width}
                      notif={item}
                      setpuntiAbbastanza={setpuntiAbbastanza}
                    />
                  ) : item.type === 'promotion' ? (
                    <Notification
                      key={item.id}
                      data={arr}
                      notif={item}
                      bg={item.img}
                      promo
                      width={item.width}
                    />
                  ) : (
                    <Notification
                      key={item.id}
                      data={arr}
                      notif={item}
                      bg={item.img}
                      width={item.width}
                    />
                  )}
                </>
              ) : null;

            return content;
          })}
        </Container>
      </Fade>
      <Modal
        show={orderModal.isOpen || puntiAbbastanza || Boolean(error)}
        close={() => {
          dispatch(closeOrderModal());
          setpuntiAbbastanza(false);
          dispatch(addToCartClearError());
        }}
        isFixed={!isReviews}
        alert={Boolean(error)}
      >
        {singleProductLoading && !isReviews ? (
          <Loader show={singleProductLoading} />
        ) : Boolean(error) ? (
          <Text>{error}</Text>
        ) : isReviews ? (
          <Recensione product={product} />
        ) : (
          <ReactShadowScroll
            style={{ width: '100%', maxHeight: '80vh' }}
            isShadow={false}
            scrollColor={'#cccccc'}
            scrollColorHover='gray'
            styleSubcontainer={{ overflowX: 'hidden' }}
          >
            {!puntiAbbastanza && orderModal.isOpen ? (
              <ProductDetails
                product={product}
                setIsReviews={setIsReviews}
                setpuntiAbbastanza={setpuntiAbbastanza}
              />
            ) : (
              <Text>Non hai abbastanza punti</Text>
            )}
          </ReactShadowScroll>
        )}
      </Modal>
    </>
  );
};

export default Premi;
