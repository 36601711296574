import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const GlobalFont = (props) => {
	const { font_source } = props;

	return (
		<Helmet>
			<link href={`${font_source}`} rel="stylesheet"></link>
		</Helmet>
	);
};

GlobalFont.propTypes = {
	font_source: PropTypes.string,
};

export default GlobalFont;
