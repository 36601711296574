import React from 'react';
import { css } from 'styled-components';
import styled from 'styled-components';
import { respondTo } from '../../../../theme/mixin';

import {
  space,
  lineHeight,
  fontSize,
  fontStyle,
  size,
  color,
  colorStyle,
  textStyle,
  fontFamily,
  fontWeight,
  letterSpacing,
  borderRadius,
} from 'styled-system';

const StyledDynamicText = styled.span`
  ${space}
  ${fontSize}
  ${fontStyle}
  ${color}
  ${size}
  ${colorStyle}
  ${textStyle}
  ${lineHeight}
  ${letterSpacing}
  ${fontFamily}
  ${fontWeight}
  ${borderRadius}
  ${props =>
    props.fl &&
    css`
      &::first-letter {
        font-weight: 900;
        text-transform: uppercase;
      }
    `}
  ${props =>
    props.underline &&
    css`
      text-decoration: underline;
    `}
  ${props =>
    props.ls &&
    css`
      letter-spacing: ${props => `${props.ls}px`};
    `}

  text-align: ${props => (props.align ? props.align : 'left')};
  color: ${props => (color ? color : props.theme.text.primary)};
  font-weight: ${({bold})=>bold};
  ${props =>
    props.secondary &&
    css`
      color: ${props => props.theme.text.secondary};
    `}
  ${props =>
    props.primary &&
    css`
      color: ${props => props.theme.text.primary};
    `}
  ${props =>
    props.success &&
    css`
      color: #4dc274;
    `}
  ${props =>
    props.error &&
    css`
      color: #ff0000;
    `}
    
  ${respondTo.sm`
    color: ${props => (props.switchcolor ? (props.dark_text ? '#000' : '#fff') : color)};
  `}
`;

const DynamicText = ({ children, textStyles, ...props }) => {
  const WithComponent = StyledDynamicText;
  return (
    <WithComponent {...props} {...textStyles[props.type]}>
      {children}
    </WithComponent>
  );
};

export default DynamicText;
