import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Text, Box, Wrapper, Flex, Button } from '../../components/Ui';
import { numberWithDots } from '../../utils/utils';

function OrderCompleted() {
  const { checkout } = useSelector(state => state.products);

  const history = useHistory();
  const pointsLabel = useSelector(state => state.app.config.points_label);
  useEffect(() => {
    if (!checkout) {
      history.push(`/user/options/ordini`);
    }
  }, [checkout]);

  return (
    <Wrapper>
      <Text primary bold>
        {checkout?.message}
      </Text>
      {checkout?.order_items.map(({ title, orderNumber, date, status, points }) => (
        <Box width='650px' margin='10px 0'>
          <Text text_box bold capit margin='5px 0'>
            {title}
          </Text>
          <Text text_box capit margin='5px 0'>
            numero d'ordine: {orderNumber}
          </Text>
          <Text text_box capit margin='5px 0'>
            data: {moment(date).format('DD/MM/YYYY HH:MM')}
          </Text>
          <Text text_box capit margin='5px 0'>
            {pointsLabel}: {numberWithDots(points)}
          </Text>
          <Text text_box capit margin='5px 0'>
            stato: {status}
          </Text>
        </Box>
      ))}
      <Flex>
        <Button active style={{ marginRight: '10px', marginBottom: '10px' }}>
          <Link to='/user/options/ordini'>Vai ai tuoi ordini</Link>
        </Button>
        <Button active style={{ marginBottom: '10px' }}>
          <Link to='/catalog'>Continua gli acquisti</Link>
        </Button>
      </Flex>
    </Wrapper>
  );
}

export default OrderCompleted;
