import React, { useEffect, useRef, useState } from 'react';
import { BsSignpost2, BsTelephone } from 'react-icons/bs';
import { FiMapPin, FiUser } from 'react-icons/fi';
import styled from 'styled-components/macro';
import { ReactComponent as IconEmail } from '../../../../assets/images/contact.svg';
import { ReactComponent as PasswordIcon } from '../../../../assets/images/password-icon.svg';
import { ReactComponent as Search } from '../../../../assets/images/search.svg';
import Checkbox from '../Checkbox/Checkbox';

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  //background-color: #fff;
  flex-grow: 1;
  svg {
    background-color: ${({ icon, theme }) => icon === 'search' && theme.bg_grey_light};
    height: 50px;
    width: 47px;
    padding: 10px;
  }
  .show_pw {
    width: 100%;
    margin: 15px 0 0 45px;
  }
`;

const InputField = styled.input`
  // border: none;
  color: ${props => props.theme.colore_testo_campi};
  padding: 0 15px;
  background-color: ${props => props.theme.input.bg};
  border-radius: ${props => props.theme.border_radius_generale_campi};
  width: ${props => (props.type === 'checkbox' ? '20px' : 'auto')};
  flex-grow: 1;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  opacity: ${props => (props.readonly ? '0.3' : '1')};
  border: 2px solid
    ${props => {
      return (!props.isvalid && props.istouched && props.firstSubmit) ||
        (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.input.bg;
    }};
  &::placeholder {
    color: #47545d59;
  }
`;

const InputIcon = styled.div`
  width: 50px;
  text-align: center;
  svg {
    width: 30px;
    height: 42px;
    padding: 0;
    color: ${({ theme }) => theme.color_link};
  }
`;

const Input = props => {
  const [passwordShown, setPasswordShown] = useState(0);
  const pasteRef = useRef();
  useEffect(() => {
    const pasteHandler = function (e) {
      e.preventDefault();
    };
    if (props.noPaste) {
      pasteRef.current.addEventListener('paste', pasteHandler, false);
    }
    return () => {
      pasteRef.current.removeEventListener('paste', pasteHandler, false);
    };
  }, []);

  const togglePassword = e => {
    if (props.form) {
      const updateForm = {
        ...props.form,
      };
      updateForm['password'].elementConfig.type = e.target.checked ? 'text' : 'password';
      if (updateForm['repeatpassword']) {
        updateForm['repeatpassword'].elementConfig.type = e.target.checked ? 'text' : 'password';
      }

      props.setForm && props.setForm(updateForm);
    }

    setPasswordShown(e.target.checked ? 1 : 0);
  };
  let icon = null;
  switch (props.icon) {
    case 'username':
      icon = <IconEmail />;
      break;
    case 'password':
      icon = <PasswordIcon />;
      break;
    case 'email':
      icon = <IconEmail />;
      break;
    case 'firstname':
    case 'lastname':
      icon = <FiUser />;
      break;
    case 'phone':
      icon = <BsTelephone />;
      break;
    case 'adress':
      icon = <FiMapPin />;
      break;
    case 'zipcode':
      icon = <BsSignpost2 />;
      break;

    default:
      break;
  }

  return (
    <InputWrap icon={props.icon}>
      {props.icon && props.icon !== 'search' && <InputIcon>{icon}</InputIcon>}
      <InputField
        {...props}
        {...props.elementConfig}
        type={passwordShown && props.elementConfig.type === 'password' ? 'text' : props.type}
        ref={pasteRef}
      />
      {props.showPwd && (
        <div className='show_pw'>
          <Checkbox
            label='Mostra password'
            name='showPwd'
            id='showPwd'
            onChange={togglePassword}
            value={passwordShown}
          />
        </div>
      )}
      {props.icon === 'search' && <Search className='colorSearch' />}
    </InputWrap>
  );
};

export default Input;
