import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { respondTo } from '../../../theme/mixin';

const Box = props => {
  return <BoxWrap {...props}>{props.children}</BoxWrap>;
};

const BoxWrap = styled.div`
  overflow: hidden;
  background-color: ${props => (props.primary ? props.theme.primary : props.theme.bg_box)};
  /*   box-shadow: 0px 5px 15px #00000026; */
  border-radius: ${({ theme }) => theme.border_radius_generale_grande};
  padding: ${({ padding }) => (padding ? padding : '10px')};
  height: ${props => (props.fullheight ? '100%' : 'auto')};
  flex-shrink: ${({ shrink }) => shrink};
  border: ${({ border, theme }) => (border ? border : '1px solid ' + theme.colore_bordo_box)};
  margin: ${({ margin }) => margin};
  width: 100%;
  ${respondTo.sm`
    width: ${props => props.width}; 
  `}
`;

Box.propsTypes = {
  align: PropTypes.string,
  justify: PropTypes.string,
  wrap: PropTypes.string,
  fullheight: PropTypes.bool,
  primary: PropTypes.bool,
};

export default Box;
