import React from 'react';
import { Wrapper } from '../../components/Ui';
import { useSelector } from 'react-redux';
import CmsBlocks from '../../components/CmsBlocks';

function Participate() {
  const participate = useSelector(state => state.cms.howToParticipate);

  return (
    <Wrapper>
      <CmsBlocks cms={participate} />
    </Wrapper>
  );
}

export default Participate;
