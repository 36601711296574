import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { respondTo } from '../../../theme/mixin';

const Col = props => {
  return <ColWrap {...props}>{props.children}</ColWrap>;
};

const ColWrap = styled.div`
  width: 100%;
  padding: ${props => props.padding}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  background-color: ${props =>
    props.bgColor ? props.bgColor : props.bgOption ? props.theme.bgOption : 'transparent'};
  text-align: ${({ alignResp }) => (alignResp ? alignResp : 'left')};

  ${respondTo.sm`
    width: ${props => (props.width ? props.width + '%' : props.widthPx ? props.widthPx : '')}; 
    text-align: ${props => props.align};
  `}
  background: ${({ bg, bgImage }) => (bg ? bg : bgImage ? bgImage : 'none')};
`;

Col.defaultProps = {
  //width: 100,
  padding: 15,
  marginBottom: 0,
};

Col.propsTypes = {
  width: PropTypes.number,
  padding: PropTypes.number,
};

export default Col;
