import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import CheckoutForm from '../../components/Checkout';
import { Modal, Text } from '../../components/Ui';
import { checkoutErrorClear, clearOrder } from '../../store/actions/products';

const Stripe = React.lazy(() => import('../../components/Stripe'));

function Checkout() {
  const { newOrder } = useSelector(state => state.product);
  const has_fee = useSelector(state => state.app?.config?.has_fee);
  const { error } = useSelector(state => state.products || {});
  const dispatch = useDispatch();
  const history = useHistory();
  const [checkoutStep, setcheckoutStep] = useState(false);

  useEffect(() => {
    if (newOrder.completed && !has_fee) {
      dispatch(clearOrder());
      history.push('/order_completed');
    }
  }, [newOrder.completed]);

  return (
    <>
      {checkoutStep ? <Stripe /> : <CheckoutForm nextStep={setcheckoutStep} />}

      <Modal
        show={Boolean(error)}
        close={() => {
          dispatch(checkoutErrorClear());
          dispatch(clearOrder());
          history.push(`/user/options/ordini`);
        }}
        alert
      >
        <Text>{error}</Text>
      </Modal>
    </>
  );
}

export default Checkout;
