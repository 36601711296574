import React from 'react';

import CmsBlocks from '../CmsBlocks';
import { useSelector } from 'react-redux';

import Fade from '../Fade';

function Missioni() {
  const mission = useSelector(state => state.cms.missions);
  return (
    <Fade>
      <CmsBlocks cms={mission} />
    </Fade>
  );
}

export default Missioni;
