import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { Box, Button, Col, Flex, Loader, Modal, Text, Wrapper } from '../../components/Ui';

import { useParams } from 'react-router';
import Datepicker from '../../components/Ui/Input/Datepicker/Datepicker';
import Select from '../../components/Ui/Input/Select/Select';
import customAxios from '../../config/axios-refresh-token';
import { getGames, getPromoInfo } from '../../store/actions';
import { respondTo } from '../../theme/mixin';
import NotFound from '../NotFound';
const Gallery = () => {
  const { checkLoading, game, error404 } = useSelector(state => state.promo || {});
  const pdv = useSelector(state => state.app.config.stores);

  const { id: idGame, i18l: i14l } = useSelector(state => state.game?.games?.[0] || {});
  const { gallery: galleryContent } = i14l || {};

  let avgRate = 4;
  const [count, setCount] = useState(8);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [voted, setVoted] = useState(false);
  const [checked, setChecked] = useState(false);
  const [regolaAccepted, setRegolaAccepted] = useState(false);
  const [showPhoto, setShowPhoto] = useState('');
  const [filterDateFrom, setFilterDateFrom] = useState(null);
  const [filterDateTo, setFilterDateTo] = useState(null);
  const [galleryData, setGalleryData] = useState([]);

  const promo = useSelector(state => state.promo.promoinfo);
  const {
    pdf_regolamento,

    gallery,
    i18l,
    img_desktop,
    img_mobile,
    dark_text,
  } = promo || {};

  const [activePage, setActivePage] = useState(1);
  const [tottalItems, setTottalItems] = useState(0);
  const theme = useTheme();

  const { subtitle, title } = i18l || {};
  var options = {
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'custom-slider',
    infinite: true,
    dots: true,
    arrows: false,
    adaptiveHeight: true,
    dotsClass: 'slider-dots',
    autoplay: true,
  };
  //const { state } = useLocation();
  const { slug } = useParams();
  //const { slug, id: idGame } = state || {};
  const dispatch = useDispatch();
  const [store, setStore] = useState(null);
  // Function to load more items on button click
  const loadMore = () => {
    setCount(count + 4); // Increment count by 10
  };

  useEffect(() => {
    dispatch(getGames(slug, false));
    dispatch(getPromoInfo(slug, 'skip'));
  }, []);

  /*   useEffect(() => {
    if (idGame) {
      customAxios
        .get(`game/${slug}/${idGame}/ratephotos`, { params: { per_page: 50 } })
        .then(response => {
          setTottalItems(response.data.gallery.length);
          setGalleryData(prev => [...prev, ...response.data?.gallery]);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [idGame, slug]); */

  useEffect(() => {
    if (idGame) {
      const filters = {
        ...(store && { store_id: store }),
        ...(filterDateFrom && {
          date_from: new Intl.DateTimeFormat('it').format(new Date(filterDateFrom)),
        }),
        ...(filterDateTo && {
          date_to: new Intl.DateTimeFormat('it').format(new Date(filterDateTo)),
        }),
      };
      setIsLoading(true);
      customAxios
        .get(`game/${slug}/${idGame}/ratephotos?page=${activePage}`, {
          params: { ...filters, per_page: 50 },
        })
        .then(response => {
          //setData(prev => ({ ...prev, ...response.data }));
          setData(prev => response.data);
          setGalleryData(prev => [...prev, ...response.data?.gallery]);
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  }, [idGame, activePage, slug]);

  if (error404.length) {
    return <NotFound type='promo' />;
  }
  const photoClickHandler = url => {
    setShowPhoto(url);
  };
  const storeHandler = e => {
    setStore(e.target.value);
  };
  const clickFilterHandler = () => {
    setIsLoading(true);
    const filters = {
      ...(store && { store_id: store }),
      ...(filterDateFrom && {
        date_from: new Intl.DateTimeFormat('it').format(new Date(filterDateFrom)),
      }),
      ...(filterDateTo && {
        date_to: new Intl.DateTimeFormat('it').format(new Date(filterDateTo)),
      }),
    };
    customAxios
      .get(`game/${slug}/${idGame}/ratephotos?page=1`, {
        params: filters,
      })
      .then(response => {
        setData(response.data);
        setActivePage(1);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  const handlePageChange = () => {
    setActivePage(prev => prev + 1);
  };

  return (
    <>
      <CustomWrapper>
        <div className='banner'>
          <Text color={theme.footer_bg} upper bold size={35}>
            photogallery
          </Text>
        </div>
        <div className='filter'>
          {pdv.length > 0 && (
            <Select className='point_filer' onChange={storeHandler}>
              <option value=''>Punti vendita</option>
              {pdv.map(item => (
                <option value={item.id} key={item.id}>
                  {item.store}
                </option>
              ))}
            </Select>
          )}
          <div className='search'>
            <div className='dates'>
              <Datepicker
                lang='it'
                selected={filterDateFrom}
                onChange={date => setFilterDateFrom(date)}
                maxDate={new Date()}
                elementConfig={{ placeholder: 'Data da' }}
                validation={{ maxDate: '', minDate: '' }}
                className='datepicker'
                onKeyDown={e => {
                  e.preventDefault();
                  if (e.keyCode == 8 || e.keyCode == 46) {
                    setFilterDateFrom(null);
                  }
                }}
              />
              <Datepicker
                lang='it'
                selected={filterDateTo}
                onChange={date => setFilterDateTo(date)}
                maxDate={new Date()}
                elementConfig={{ placeholder: 'Data a' }}
                validation={{ maxDate: '', minDate: '' }}
                className='datepicker'
                onKeyDown={e => {
                  e.preventDefault();
                  if (e.keyCode == 8 || e.keyCode == 46) {
                    setFilterDateTo(null);
                  }
                }}
              />
            </div>
            <Button onClick={clickFilterHandler}>cerca</Button>
          </div>
        </div>
        {isLoading ? (
          <div style={{ margin: '20px' }}>
            <Loader show={isLoading} />
          </div>
        ) : galleryData?.length ? (
          <Grid>
            {galleryData?.map((item, i) => (
              <GalleryItem data={item} key={item.id} clickHandler={photoClickHandler} />
            ))}
          </Grid>
        ) : (
          <Text align='center'>Nessuna foto trovata</Text>
        )}
        {activePage < data?.total_pages && (
          <Button active onClick={() => handlePageChange()} style={{ margin: '20px auto' }}>
            Show more
          </Button>
        )}
        {/* {data.total_pages > 1 && (
          <Pagination
            totalItemsCount={data.total_pages * 15}
            onChange={handlePageChange}
            activePage={activePage}
            itemsCountPerPage={15}
            pageRangeDisplayed={5}
            itemClass='page_btn'
          />
        )} */}
      </CustomWrapper>

      <Modal
        show={error}
        close={() => {
          setError();
        }}
      >
        {error}
      </Modal>
      <Modal
        show={showPhoto}
        close={() => {
          setShowPhoto('');
        }}
      >
        <img src={showPhoto} alt='' />
      </Modal>
    </>
  );
};

const GalleryItem = ({ data, voteHandler, clickHandler, myvotes }) => {
  // const [voted, setVoted] = useState(false);
  const { id, photo_date, photo_url, store_name, voted, votes } = data;
  const [isVoting, setIsVoting] = useState(false);
  return (
    <CustomBox onClick={() => clickHandler(photo_url)}>
      <img src={photo_url} alt='' />
    </CustomBox>
  );
};

const OverlayRegola = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 15px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-bottom: 30px;
  ${respondTo.med` 
      grid-template-columns: repeat(2, 1fr);
     `};
  ${respondTo.md` 
     grid-template-columns: repeat(3, 1fr);
     `};
  ${respondTo.lg` 
    grid-template-columns: repeat(5, 1fr);
     `};
`;

const CustomBox = styled(Box)`
  ${respondTo.med` 
     
    `};
  ${respondTo.md` 
   
    `};
  ${respondTo.lg` 
  
    `};
  cursor: pointer;
  .voted {
    position: absolute;
    top: 0;
    right: 30px;
    background-color: #002d72;
    color: #fff;
    padding: 8px;
    text-transform: uppercase;
    font-size: 9px;
    display: ${({ active }) => (active ? 'block' : 'none')};
    border-radius: 0 0 10px 10px;
  }
  img {
    border-radius: inherit;
    max-width: 100%;
    max-height: 211px;
    margin: 0 auto;
    display: block;
  }
  span {
    font-size: 12px;
  }
  svg {
    margin-right: 10px;
  }
  .content {
    flex-grow: 1;
  }
  .voteNum {
    margin-left: 10px;
  }
`;
const CustomWrapper = styled(Wrapper)`
  .active {
    border: ${({ border, theme }) =>
      border ? '1px solid ' + theme.colore_bordo_box : '1px solid ' + theme.bordo_bottone};
    background-color: ${({ theme }) => theme.bg_bottoni} !important;
    color: ${({ color, theme, secondary }) =>
      secondary
        ? theme.colore_testo_bottoni_secondari
        : color
        ? color
        : theme.colore_testo_bottoni || theme.default_text_color};
  }
  .page_btn {
    padding: 2px 10px;
  }
  .banner {
    text-align: center;
  }
  .filter {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .point_filer {
    width: auto;
    background-color: ${({ theme }) => theme.bg_bottoni};

    height: auto;
    border: ${({ border, theme }) =>
      border ? '1px solid ' + theme.colore_bordo_box : '1px solid ' + theme.bordo_bottone};
    border-radius: ${({ theme }) => theme.border_radius_generale_piccolo};
    color: ${({ color, theme, secondary }) =>
      secondary
        ? theme.colore_testo_bottoni_secondari
        : color
        ? color
        : theme.colore_testo_bottoni || theme.default_text_color};
    flex-grow: 1;
    padding: 5px 0;
    text-align: center;
    ${respondTo.md` 
      padding:0  10px;
      flex-grow: 0
    `};
  }
  .search {
    display: flex;
    gap: 10px;
    padding: 5px;
    background-color: ${({ theme }) => theme.footer_bg};
    border-radius: 5px;

    .datepicker {
      height: auto;
      border-radius: 5px;
    }

    button {
      height: auto;
      font-size: 16px;
      font-weight: normal;
      color: #fff;
      border: none;
    }
    .dates {
      display: flex;
      gap: 10px;
    }
  }
`;
const CustomCol1 = styled(Col)`
  @media (max-width: 821px) {
    width: 100%;
  }
`;
const CustomCol2 = styled(Col)`
  .section-heading > span {
    color: #fff;
  }
  .svg {
    width: 50px;
  }
  @media (max-width: 821px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const CustomFlex = styled(Flex)`
  margin-bottom: 30px;
  background-color: transparent;
  z-index: 5;
  position: relative;
  .pdf_btn {
    margin-top: 15px;
    @media (min-width: 767px) {
      margin-top: 0;
    }
  }
  .qui {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  ${respondTo.sm` 
      margin-top: -60px;
    `};
`;
const HiwBanner = styled.div``;
const TablePoints = styled.table`
  margin-top: 15px;
  width: 100%;
  border-collapse: collapse;

  thead {
    td {
      padding: 10px 20px;
      background-color: ${props => props.theme.primary};
      border-left: 1px solid #fff;
    }
  }

  tbody {
    td {
      font-size: 15px;
      padding: 10px 20px;
      border-bottom: 1px solid #a3aec5;
    }
  }
`;

const DownloadRegulation = styled.div`
  height: 230px;
  position: relative;
  margin-top: 30px;
  padding: 15px;

  & > div {
    position: relative;
    z-index: 1;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const SecondSlid = styled.div`
  .hiw-text {
    ${respondTo.sm` 
      margin-right:0;
      width:35.5%;
      right:auto;
      left:35px;
    `};
  }
`;
export default Gallery;
