import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const SwitchLangContainer = styled.ul`
	display: flex;
	align-items: center;
	justify-content: center;
	& li {
		cursor: pointer;
		display: inline-block;
		margin-left: ${(props) => (props.insidebar ? "0px" : "20px")};
		&:first-child {
			margin-right: ${(props) => (props.insidebar ? "20px" : "0px")};
		}
	}
`;

const SwitchLang = (props) => {
	const [t, i18] = useTranslation();

	const { bootApp, langs, insidebar } = props;

	const changeLang = (lang) => {
		bootApp();
		i18.changeLanguage(lang);
	};

	let content = null;
	content = langs.map((lg) => {
		return (
			<li key={lg} onClick={() => changeLang(lg)}>
				{lg}
			</li>
		);
	});

	return (
		<SwitchLangContainer insidebar={insidebar}>{content}</SwitchLangContainer>
	);
};

SwitchLang.propTypes = {
	appInit: PropTypes.func,
	langs: PropTypes.array,
};

export default SwitchLang;
