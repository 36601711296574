import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Loader, Modal } from '../../components/Ui';
import { signinClearError } from '../../store/actions';
import { fakeAuthInit } from '../../store/actions/auth';

function FakeLogin() {
  const dispatch = useDispatch();
  const isLogged = useSelector(state => state.auth.token);
  const loading = useSelector(state => state.auth.loading);
  const error = useSelector(state => state.auth.error?.data?.error);
  const { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    dispatch(fakeAuthInit(token));
  }, []);

  useEffect(() => {
    if (isLogged) {
      history.push('/');
    }
  }, [isLogged]);

  return (
    <>
      <Loader initial show={loading} />
      <Modal
        show={Boolean(error)}
        close={() => {
          dispatch(signinClearError());
          history.push('/');
        }}
        alert
      >
        {error}
      </Modal>
    </>
  );
}

export default FakeLogin;
