import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  news: [],
  singleNews: null,
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_NEWS_SUCCESS:
      return {
        ...state,
        news: action.news,
      };
    case actionTypes.GET_SINGLE_NEWS_SUCCESS:
      return {
        ...state,
        singleNews: action.news,
      };

    default:
      return state;
  }
};

export default newsReducer;
