import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '../Ui';
import { SingleExternalOauth } from '../Oauths';
import { useSelector } from 'react-redux';

const LoginBtn = props => {
  //const { label, ownlogin, oauths, oauthinfo } = props;
  const { label } = props;
  const { oauths, oauthinfo, ownlogin } = useSelector(state => state.app.config);
  let content = null;

  if (oauths !== undefined) {
    if (oauthinfo !== null) {
      if (ownlogin) {
        content = (
          <Link to='/auth/login'>
            <Button small>{label}</Button>
          </Link>
        );
      } else {
        content = (
          // The only option available is to have one direct external login (Es. Stardust play).
          <SingleExternalOauth
            label={label}
            oauths={oauths}
            oauthInfo={oauthinfo}
            small
            isfullwidth={false}
          />
        );
      }
    }
  }

  return <React.Fragment>{content}</React.Fragment>;
};

LoginBtn.propTypes = {
  label: PropTypes.string,
  oauths: PropTypes.array,
  oauthinfo: PropTypes.object,
  ownlogin: PropTypes.number,
};

export default LoginBtn;
