import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useCart = () => {
  const [state, setState] = useState(false);
  const option = useSelector(state => JSON.parse(state.app.config.settings.option) || {});
  useEffect(() => {
    setState(option?.menuSettings?.cart?.visibility === 'show');
  }, [option]);

  return state;
};

export default useCart;
