import React from 'react';
import styled from 'styled-components';
import { Box, Col, Flex, Text } from '../../Ui';
const Container = styled.div`
  padding: 10px;
`;
const Content = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.bgOption};
`;
function Soglia() {
  return (
    <Box padding='20px 40px'>
      <Text as='h2' size={35} margin='0 0 10px 0' capit text_box>
        soglia 1
      </Text>
      <Text as='p' text_box upper>
        TOT. CREDITI
      </Text>
      <Text as='h2' size={65} padding='10px 0' text_box>
        2.500
      </Text>
      <Flex justify='space-between' wrap='nowrap'>
        <Col width={68} bgOption padding='10' borderRadius={5} marginBottom={0}>
          <Text as='p' bold upper text_box width='150px' margin='0 0 10px 0'>
            PUNTI OTTENIBILI AL RAGGIUNGIMENTO
          </Text>
          <Text as='p' bold size={20} text_box>
            +2.500 pt
          </Text>
        </Col>
        <Col width={30} marginBottom={0}>
          <Text as='p' bold upper text_box width='130px'>
            PERCENTUALE COMPLETAMENTO
          </Text>
          <Text as='p' size={30} bold text_box>
            0%
          </Text>
        </Col>
      </Flex>
    </Box>
  );
}

export default Soglia;
