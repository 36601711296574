import React from 'react';
import styled from 'styled-components';
import { Text } from '../Ui';
const Container = styled.div`
  .tech {
    &-content {
      display: flex;
      justify-content: space-between;
    }
    &-title {
      padding: 5px;
      background-color: ${({ theme }) => theme.bgboard};
    }
    &-content {
      padding: 5px;
      &:nth-child(2n + 1) {
        background-color: ${({ theme }) => theme.bgboard};
      }
    }
  }
`;
function Techs({ data }) {
  return data.map(({ category, features }) => (
    <Container className='tech'>
      <Text bold size={16} primary className='tech-title'>
        {category}
      </Text>
      {features.map((elem, i) => (
        <div className='tech-content' key={i + elem.label + elem.value}>
          <Text className='tech-label' bold size={16} width='49%'>
            {elem.label}
          </Text>
          <Text className='tech-value' size={16} width='49%'>
            {elem.value}
          </Text>
        </div>
      ))}
    </Container>
  ));
}

export default Techs;
