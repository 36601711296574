import React from 'react';
import styled from 'styled-components';
import { Button } from '../index';
import { useTranslation } from 'react-i18next';
import { respondTo } from '../../../theme/mixin';
import { Text } from '../../Ui';

const StartDiv = styled(Button)`
  font-size: 24px;
  height: auto;
  border-radius: 0;
  padding: 8px;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
  ${respondTo.sm`
		font-size: 32px;
	`}
`;
const Cont = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const Cont2 = styled.div`
  flex: 1;
  text-align: center;
`;

const TextContainer = styled.div`
  margin-bottom: 22px;
  ${respondTo.sm`
		margin-bottom: 30px;
	`}
`;

const StartGame = props => {
  const { clicked } = props;
  const [t] = useTranslation();

  return (
    <Cont>
      <Cont2>
        <TextContainer>
          <Text as='p' type='scream' fl align='center'>
            Sei pronto?
          </Text>
        </TextContainer>
        <Button active onClick={clicked}>
          {t('games.startgame')}
        </Button>
      </Cont2>
    </Cont>
  );
};

export default StartGame;
