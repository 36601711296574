import * as actionTypes from './actionTypes';
import customaxios from '../../config/axios-refresh-token';

const getAllNewsSuccess = news => {
  return {
    type: actionTypes.GET_ALL_NEWS_SUCCESS,
    news,
  };
};

export const getAllNews = () => {
  let endpoint = '/news';
  return dispatch => {
    customaxios
      .get(endpoint)
      .then(result => {
        if (result.status === 200) {
          dispatch(getAllNewsSuccess(result.data));
        }
      })
      .catch(error => {});
  };
};
const getNewsSuccess = news => {
  return {
    type: actionTypes.GET_SINGLE_NEWS_SUCCESS,
    news,
  };
};
export const getSingleNews = id => {
  let endpoint = `/news/${id}/read`;
  return dispatch => {
    return customaxios
      .post(endpoint)
      .then(result => {
        if (result.status === 200) {
          dispatch(getNewsSuccess(result.data));
        }
      })
      .catch(error => {
        //error);
      });
  };
};

export const deleteSingleNews = id => {
  let endpoint = `/news/${id}/delete`;
  return dispatch => {
    return customaxios
      .post(endpoint)
      .then(result => {})
      .catch(error => {});
  };
};
