import styled from 'styled-components';

const MaskImg = styled.div`
  height: 50px;
  width: 50px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colore_testo_box};
  -webkit-mask-image: url(${({ src }) => src});
  mask-image: url(${({ src }) => src});
  -webkit-mask-size: 100%;
  mask-size: 100%;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
`;
export default MaskImg;
