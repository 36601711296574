import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { Button } from '../Ui';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
    padding: 20px;
    text-align: center;
`;

const Message = styled.div`
    margin-bottom: 12px;
`;

const ErrorReload = props => {

    const [t] = useTranslation();

    const { 
        clicked,
        param
     } = props;

    return (
        <Container>
            <Message>{t('error.wentwrong')}</Message>
            {param ? <Button onClick={() => clicked(param)} small>{t('error.reload')}</Button> : <Button onClick={clicked} small>{t('error.reload')}</Button> }
            
        </Container>
    )
}

ErrorReload.propTypes = {

}

export default ErrorReload
