import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import CustomSlider from '../customSlider';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { BiReset } from 'react-icons/bi';
import useMedia from '../../hooks/useMedia';
import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from 'react-image-magnifiers';
const Container = styled.div`
  position: relative;
  & .custom-slider img {
  }
`;
const BoxImg = styled.div`
  cursor: pointer;
  height: 50px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  ${respondTo.md`
       height: 100px; 
  `}
`;
const SmallImg = styled.img`
  margin: auto;
  width: auto;
  max-height: 50px;
  max-width: 100%;
  ${respondTo.md`
   max-width: 100px;
  max-height: 70px;
 
  `}
`;
const BigImg = styled.div`
  width: 100%;
  height: 250px;
  margin: 0 auto;
  //overflow: hidden;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: zoom-in;
  .zoom {
    img {
      max-width: unset !important;
      height: 250px;
      width: auto !important;
    }
    > div {
      box-shadow: 0px 2px 10px #00000026;
      img {
        height: 300%;
      }
    }
  }

  ${respondTo.sm`
      width: 300px;
     
  `}
`;

function ImagesCarousel({ images }) {
  const [showImg, setShowImg] = useState('');
  const mediaQuery = useMedia('(max-width:768px)');
  useEffect(() => {
    setShowImg(images[0]?.big);
  }, []);
  var settings = {
    slidesToShow: images.length >= 1 && images.length < 4 ? images.length : 4,
    slidesToScroll: 1,
    className: 'custom-slider',
    infinite: images.length < 4 ? false : true,
    arrows: true,
  };
  const ref = useRef();

  return (
    <Container>
      <BigImg>
        {mediaQuery ? (
          <img src={showImg} />
        ) : (
          <GlassMagnifier
            imageSrc={showImg}
            imageAlt='image'
            largeImageSrc={showImg}
            magnifierSize='300px'
            className='zoom'
            allowOverflow='false'
            //magnifierBorderColor='red'
          />
        )}
      </BigImg>
      {images.length > 1 && (
        <CustomSlider settings={settings}>
          {images.map(item => (
            <BoxImg>
              <SmallImg
                src={item.small}
                onClick={() => {
                  setShowImg(item.big);
                  // ref.current.click();
                }}
              />
            </BoxImg>
          ))}
        </CustomSlider>
      )}
    </Container>
  );
}

export default ImagesCarousel;
/* import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import CustomSlider from '../customSlider';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { BiReset } from 'react-icons/bi';
import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from 'react-image-magnifiers';
const Container = styled.div`
  position: relative;
  & .custom-slider img {
  }
  .zoom {
    img {
      max-width: unset !important;
    }
  }
`;
const BoxImg = styled.div`
  cursor: pointer;
  height: 100px;
  margin: 0 5px;
  display: flex;
  align-items: center;
`;
const SmallImg = styled.img`
  margin: auto;
  width: auto;
  max-height: 70px;
  max-width: 100px;
`;
const BigImg = styled.div`
  width: 100%;
  height: 250px;
  margin: 0 auto;
  //overflow: hidden;
  padding-bottom: 10px;
  /*  display: flex;
  justify-content: center;
  align-items: center; */
//cursor: zoom-in;

/*   img {
    width: auto;
    margin: auto;
    //max-width: 400px;
    max-height: 250px;
  } */
/*  ${respondTo.sm`
  width: 300px;
 
`} */
/* `; */
/*
function ImagesCarousel({ images }) {
const [showImg, setShowImg] = useState('');
useEffect(() => {
setShowImg(images[0]?.big);
}, []);
var settings = {
slidesToShow: images.length >= 1 && images.length < 4 ? images.length : 4,
slidesToScroll: 1,
className: 'custom-slider',
infinite: images.length < 4 ? false : true,
arrows: true,
};
const ref = useRef();

return (
<Container>
  <BigImg>
    <GlassMagnifier
      imageSrc='https://picsum.photos/id/237/200/300'
      imageAlt='Example'
      largeImageSrc='https://picsum.photos/id/237/1100/1600'
      magnifierSize='200px'
      className='zoom'
    />
  </BigImg>
  {images.length > 1 && (
    <CustomSlider settings={settings}>
      {images.map(item => (
        <BoxImg>
          <SmallImg
            src={item.small}
            onClick={() => {
              setShowImg(item.big);
              ref.current.click();
            }}
          />
        </BoxImg>
      ))}
    </CustomSlider>
  )}
</Container>
);
}

export default ImagesCarousel;
*/
