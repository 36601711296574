import React, { useState } from 'react';
import InputField from '../InputField/InputField';

const InputPrice = props => {
  const [isEditing, setIsEditing] = useState(false);

  const formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  return (
    <InputField
      {...props.elementConfig}
      {...props}
      type='text'
      value={props.value ? formatter.format(props.value.replace(/\D/g, '')) : ''}
    />
  );
};

export default InputPrice;
