import React from 'react';
import styled from 'styled-components';
import { MaskImg, Text } from '../Ui';
import { Link } from 'react-router-dom';
const Container = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${({ theme }) => theme.bg_menu_interno || theme.bg_grey_light};
  cursor: pointer;
  .text_color {
    color: ${({ theme }) => theme.colore_testo_menu_interno};
  }
  .icon {
    margin: 0;
    background-color: ${({ theme }) => theme.colore_testo_menu_interno};
  }

  &:hover {
    background-color: ${({ theme }) => theme.bg_hover_menu_interno};
    .text_color {
      color: ${({ theme }) => theme.colore_testo_hover_menu_interno};
    }
    svg {
      color: ${({ theme }) => theme.colore_testo_hover_menu_interno};
      fill: ${({ theme }) => theme.colore_testo_hover_menu_interno};
    }
    .icon {
      background-color: ${({ theme }) => theme.colore_testo_hover_menu_interno};
    }
  }
  svg {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    color: ${({ theme }) => theme.colore_testo_menu_interno};
    fill: ${({ theme }) => theme.colore_testo_menu_interno};
  }
  img {
    width: 50px;
  }
`;
function ProfileOption({ text, Svg, width, height, link, setToggelDropDown, img }) {
  return (
    <Link to={link}>
      <Container width={width} height={height} onClick={() => setToggelDropDown(false)}>
        <Text as='p' bold width='60%' size={16} className='text_color'>
          {text}
        </Text>
        {img ? <MaskImg src={img} className='icon' /> : <Svg size={height} color='#f00' />}
      </Container>
    </Link>
  );
}

export default ProfileOption;
