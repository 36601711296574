import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AiFillStar } from 'react-icons/ai';
import { respondTo } from '../../theme/mixin';
import { Rating } from 'react-simple-star-rating';
const Container = styled.div`
  /* display: flex;
  flex-wrap: nowrap; */
  svg {
    width: 20px;
    height: 20px;
  }
`;
const Btn = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  svg {
    color: ${({ on }) => (on ? '#fbb03b' : '#ccc')};
    font-size: 20px;
    ${respondTo.lg`
      width:${({ width }) => width};
      font-size:22px;
  `};
  }
`;

const StarRating = ({ num, numRating, setNumRating, readonly, inSlider, halfStar }) => {
  useEffect(() => {
    setNumRating(num);
  }, [num]);

  const handleRating = rate => {
    setNumRating(rate / 20);
  };
  return (
    <Container>
      <Rating
        onClick={handleRating}
        allowHalfIcon={halfStar}
        ratingValue={numRating * 20}
        readonly={readonly ? true : false}
      />
    </Container>
  );
};

export default StarRating;
