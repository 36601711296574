import React from 'react';
import styled from 'styled-components';

const ArrowDown = () => {
  const Icon = styled.svg`
    path {
      stroke: ${props => props.theme.default_color};
    }
  `;

  return (
    <Icon xmlns='http://www.w3.org/2000/svg' width='18' height='11' viewBox='0 0 18 11'>
      <g>
        <g>
          <path
            fill='none'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-miterlimit='20'
            stroke-width='2'
            d='M1.541 2.025v0l7.217 7.217v0l7.568-7.569v0'
          />
        </g>
      </g>
    </Icon>
  );
};

export default ArrowDown;
