import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Text } from '../Ui';

const TextWrapper = styled.div`
  padding: 10px 0;
  border-top: 1px solid ${props => props.border || 'transparent'};
  border-bottom: 1px solid ${props => props.border || 'transparent'};
`;

function AuthText() {
  const authcontent = useSelector(state => state.cms.authentication);
  const title_auth = authcontent[0].contents.find(e => e.name === 'title_Auth');
  const style = title_auth && JSON.parse(title_auth?.style);

  return title_auth ? (
    <TextWrapper border={style?.border || ''}>
      <Text bold align='center' type='title' color={title_auth.text_color}>
        {title_auth?.i18l.title}
      </Text>
      {title_auth?.i18l.content && title_auth?.i18l.content !== '<p>&nbsp;</p>' && (
        <Text size={16} align='center' className='mt-10' color={title_auth.text_color}>
          {title_auth?.i18l.content}
        </Text>
      )}
    </TextWrapper>
  ) : null;
}

export default AuthText;
