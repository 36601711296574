import * as actionTypes from '../actions/actionTypes';

const initialState = {
  viewer: [],
  loading: true, // oppure true??????????
  error: null,
};

const viewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_VIEWER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_VIEWER_SUCCESS:
      return {
        ...state,
        loading: false,
        //viewer: action.viewer //check if its ok
        viewer: action.viewer,
      };
    case actionTypes.GET_VIEWER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.GET_VIEWER_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        viewer: {},
      };
    default:
      return state;
  }
};

export default viewerReducer;
