import React, { useState, useEffect } from 'react';
import { Flex, Button, Text, Input } from '../Ui';
import styled from 'styled-components';
import StarRating from '../product/StarRating';
import { reviewForm } from '../../formsConfig/formsConfig';
import useForm from '../../hooks/useForm';
import { addReview, closeOrderModal, getAllProducts } from '../../store/actions/products';
import { useDispatch } from 'react-redux';
import { getUserInfo } from '../../store/actions';
const Box = styled.div`
  width: 100%;
  // border: 1px solid ${({ theme }) => theme.borderCode};
  border-radius: 5px;
  padding: 10px;
`;
const Form = styled.form``;

function Recensione({ product }) {
  const { id, rating, reviews } = product || {};

  const [numRating, setNumRating] = useState(0);
  useEffect(() => {
    setNumRating(rating);
  }, [rating]);
  const pathName = window.location.pathname;
  const isReviewForm = reviewForm();
  const dispatch = useDispatch();
  const reviewHandler = () => {
    dispatch(
      addReview(
        {
          title: formData.title.value,
          description: formData.description.value,
          stars: numRating ? numRating : 0,
        },
        id,
      ),
    )
      .then(() => {
        dispatch(closeOrderModal());
        dispatch(getUserInfo());
        dispatch(getAllProducts());
      })
      .catch(err => {
        dispatch(closeOrderModal());
      });
  };
  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    datePickerHandler,
    resetFormHandler,
  } = useForm(reviewHandler, isReviewForm);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        istouched={inp.validation.touched}
        showError={showError}
        {...inp}
      />
    );
  });

  return (
    <Box>
      <Text
        as='p'
        type='infolabel'
        upper
        bold
        primary
        style={{ cursor: 'pointer', marginBottom: '20px' }}
        text_box
      >
        INVIA RECENSIONE
      </Text>
      <Form onSubmit={formSubmitHandler}>
        {inputs}
        <Flex style={{ marginTop: '10px' }} justify='space-between'>
          {numRating >= 0 && (
            <Flex align='center'>
              <StarRating
                num={rating}
                numRating={numRating}
                setNumRating={setNumRating}
                halfStar={false}
              />
              <Text as='p' style={{ marginLeft: '5px' }}>
                ({reviews?.length})
              </Text>
            </Flex>
          )}
          <Button
            type='submit'
            size={14}
            padding='0 20px'
            style={{ backgroundImage: 'none' }}
            upper
            active
          >
            invia
          </Button>
        </Flex>
      </Form>
    </Box>
  );
}

export default Recensione;
