import React from 'react'
import PropTypes from 'prop-types'

const Page500 = props => {

    const { 
        errorCode
     } = props;

    return (
        <div>
            ERROR {errorCode}
        </div>
    )
}

Page500.propTypes = {
    errorCode: PropTypes.number
}



export default Page500
