import styled from 'styled-components';
import { respondTo } from '../../../../theme/mixin'

const LoaderViewerContainer = styled.div`
    height: 305px;
    ${respondTo.sm`
        height: 541px;
    `}
`;

export default LoaderViewerContainer;