import React from 'react';
import CountdownEl from 'react-countdown';
import styled from 'styled-components';
import { RightArrow } from 'styled-icons/boxicons-solid';
import { darken } from 'polished';
import Button from '../Button';

const CountDownContainer = styled.div`
  height: 50px;
  /* width: 60%; */
  // width: ${props => (props.isTime ? '50px' : '110px')};
  //border-radius: ${props => (props.isTime ? '50%' : '50px')};
  background-color: ${props => props.theme.bg_bottoni};
  &:hover {
    /* background-color: ${props => darken(0.1, props.theme.bg_bottoni)}; */
    //border-radius: ${props => (props.isTime ? '50%' : '50px')};
  }
`;

const NextContainer = styled(Button)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  font-size: 18px;
  color: ${props => props.theme.colore_testo_bottoni || props.theme.default_text_color};
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  & span {
    display: block;
    font-size: 14px;
    //margin-top: -8px;
  }
  & svg {
    color: ${props => props.theme.colore_testo_bottoni || props.theme.default_text_color};
  }
  &:hover {
    /* background-color: ${props => darken(0.1, props.theme.bg_bottoni)};
    border-radius: 50%; */
  }
`;

const CountDown = props => {
  const { clicked, start, time, gotonext, rend, timeComplete, next } = props;

  let nextDiv = null;

  if (timeComplete) {
    nextDiv = <NextContainer onClick={gotonext} disabled={true}></NextContainer>;
  }
  if (start) {
    nextDiv = (
      <NextContainer>
        <CountdownEl date={Date.now() + time} renderer={rend} />
      </NextContainer>
    );
  } else {
    nextDiv =
      next !== 'auto' ? (
        <NextContainer onClick={gotonext}>
          Avanti
          {/* <RightArrow size={28} /> */}
        </NextContainer>
      ) : (
        <NextContainer onClick={gotonext}>
          <RightArrow size={28} />
        </NextContainer>
      );
  }

  return (
    <React.Fragment>
      <CountDownContainer isTime={start}>{nextDiv}</CountDownContainer>
    </React.Fragment>
  );
};

export default CountDown;
