import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';

const AudioContainer = styled.div`
    width: 98%;
    margin: auto;
`;

export const Audio = (props) => {

    const { src, pause } = props;

    const audioRef = useRef(null);

    useEffect(() => {
        if(pause) {
            audioRef.current.pause();
        }

    }, [pause])

    return (
        <AudioContainer>
            <audio controls ref={audioRef}>
                <source src={src} type="audio/mpeg" />
            </audio>
        </AudioContainer>
    );
}

Audio.propTypes = {
    src: PropTypes.string,
    pause: PropTypes.bool
}

export default Audio;