import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '../../Ui';
import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';

const TitleContainer = styled.div`
  margin-bottom: 16px;
  ${respondTo.sm`
        margin-bottom: 18px;
    `}
  ${respondTo.md`
        margin-bottom: 22px;
    `}
`;

const Title = props => {
  const { children, stile,bold,color,align,type } = props;

  let st = {};
  if (stile) {
    st = JSON.parse(stile);
  }

  return (
    <TitleContainer style={st}>
      <Typography as='h4' type={type || 'title'} bold={bold} color={color} align={align}>
        {children}
      </Typography>
    </TitleContainer>
  );
};

Title.propTypes = {
  children: PropTypes.any,
  stile: PropTypes.string,
};

export default Title;
