import * as actionTypes from '../actions/actionTypes';

const initialState = {
  error500: [],
  errorApp: [],
  sessionExpired: false,
  otherLoginLoading: false,
  otherLoginSuccess: false,
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_500_ERROR:
      return {
        ...state,
        error500: state.error500.concat(action.error),
      };
    case actionTypes.SESSION_EXPIRED_ERROR:
      return {
        ...state,
        sessionExpired: true,
      };
    case actionTypes.OTHER_LOGIN_START:
      return {
        ...state,
        otherLoginLoading: true,
      };
    case actionTypes.OTHER_LOGIN_SUCCESS:
      return {
        ...state,
        otherLoginLoading: false,
        otherLoginSuccess: true,
      };
    case actionTypes.OTHER_LOGIN_END:
      return {
        ...state,
        otherLoginLoading: false,
        otherLoginSuccess: false,
      };
    case actionTypes.SET_APP_ERROR:
      return {
        ...state,
        errorApp: state.errorApp.concat(action.error),
      };
    default:
      return state;
  }
};

export default errorReducer;
