import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  products: null,
  id_catalog: null,
  allProducts: [],
  categories: [],
  brands: [],
  checkout: null,
  error: null,
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: { ...state.products, products: action.products, total_pages: action.total_pages },
      };

    case actionTypes.GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.products,
        allProducts: action.products,
      };

    case actionTypes.GET_FILTERS_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories,
      };

    case actionTypes.SET_ID_CATALOG:
      return {
        ...state,
        id_catalog: action.id,
      };

    case actionTypes.GET_FILTERS_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.brands,
      };
    case actionTypes.ORDER_COMPLETED:
      return {
        ...state,
        checkout: action.data,
      };
    case actionTypes.CHECKOUT_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.CHECKOUT_ERROR_CLEAR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default productsReducer;
