import * as actionTypes from '../actions/actionTypes';

const initialState = {
  partnerList: [],
};

const partnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PARTNERS_SUCCESS:
      return {
        ...state,
        partnerList: action.partnerList,
      };
    default:
      return state;
  }
};

export default partnersReducer;
