import React, { useState } from 'react';
import InputField from '../InputField/InputField';
import ReactDatePicker from 'react-datepicker';
import Datepicker from '../Datepicker/Datepicker';

const InputTime = props => {
  const [isEditing, setIsEditing] = useState(false);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };
  return isEditing ? (
    <Datepicker
      {...props.elementConfig}
      {...props}
      selected={props.startdate}
      onChange={props.changedDatePicker}
      onBlur={toggleEditing}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      dateFormat='h:mm aa'
    />
  ) : (
    <InputField
      {...props.elementConfig}
      {...props}
      type='text'
      value={props.value ? props.value : ''}
      onFocus={toggleEditing}
      readOnly
    />
  );
};

export default InputTime;
