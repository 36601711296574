import * as actionTypes from './actionTypes';

import axios from 'axios';

const sendLogErrorStart = () => {
  return {
    type: actionTypes.SEND_ERROR_LOG_START,
  };
};

const sendLogErrorSuccess = () => {
  return {
    type: actionTypes.SEND_ERROR_LOG_SUCCESS,
  };
};

const sendLogErrorError = error => {
  return {
    type: actionTypes.SEND_ERROR_LOG_ERROR,
    error,
  };
};

export const sendLogError = (endpoint, err) => {
  let url = window.location.href;
  const {
    response: { status },
    response: {
      data: { message, line },
    },
  } = err;
  return dispatch => {
    dispatch(sendLogErrorStart());
    axios
      .post(process.env.REACT_APP_ERROR_URL, {
        url,
        endpoint,
        code: status,
        error: `${message} at line ${line}`,
        apikey: process.env.REACT_APP_ERROR_API_KEY,
      })
      .then(result => {
        dispatch(sendLogErrorSuccess());
      })
      .catch(error => {
        dispatch(sendLogErrorError());
      });
  };
};

export const set500Error = error => {
  return {
    type: actionTypes.SET_500_ERROR,
    error,
  };
};

export const sessionExpiredError = () => {
  return {
    type: actionTypes.SESSION_EXPIRED_ERROR,
  };
};

export const otherLoginStart = () => {
  return {
    type: actionTypes.OTHER_LOGIN_START,
  };
};

export const otherLoginSuccess = () => {
  return {
    type: actionTypes.OTHER_LOGIN_SUCCESS,
  };
};

export const otherLoginEnd = () => {
  return {
    type: actionTypes.OTHER_LOGIN_END,
  };
};
