import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Loader } from '../Ui';

const AuthNav = () => {
  return (
    <AuthNavTabs>
        {{/* <Link to='/auth/login'>
          <span>Accedi</span>
        </Link> */}}
         

    </AuthNavTabs>
  );
};

const AuthNavTabs = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 50px;
  border: 2px solid ${props => props.theme.primary};
`;

const Tab = styled.div`
  width: 50%;

  a {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    

    &:hover {
      background-color: ${props => props.theme.primary};
      color: #fff;
    }

    span {
      font-size: 14px;
      font-weight: 700;
      color: ${props => (props.active ? '#fff' : '#DCE4E8')};
    }
  }
`;

Tab.propTypes = {
  active: PropTypes.bool,
};

AuthNav.propTypes = {
  active: PropTypes.string,
};

export default AuthNav;
