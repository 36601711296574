import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Col, Flex, Button, Text, Modal, Loader } from '../Ui';
import Fade from '../Fade';
import moment from 'moment';
import parse from 'html-react-parser';
import Fuse from 'fuse.js';
import Input from '../Ui/Input/InputField/InputField';
import { searchBy } from '../../utils/utils';
const CustomSearch = styled.div`
  width: fit-content;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  & > div {
    padding: 0;
    border: none;
  }
  input {
    height: 40px;
  }
  svg {
    height: 40px;
  }
`;
function Plays() {
  const plays = useSelector(state => state.user?.user?.plays);
  const [slideRows, setSlideRows] = useState([]);

  useEffect(() => {
    setSlideRows(plays);
  }, [plays]);

  return (
    <>
      <Fade>
        {slideRows?.map((win, winIndex) => (
          <Box key={winIndex} className='mb-20'>
            <Flex direction='column' justufy='center' style={{ padding: '15px' }}>
              {win.promotion && (
                <Flex className='mb-10'>
                  <Text bold size={18} capit text_box>
                    Promozione:
                  </Text>
                  <Text capit style={{ marginLeft: '5px' }} text_box>
                    {parse(win.promotion)}
                  </Text>
                </Flex>
              )}

              <Flex className='mb-10'>
                <Text bold size={18} capit text_box>
                  Data giocata:
                </Text>
                <Text capit style={{ marginLeft: '5px' }} text_box>
                  {win.datetime}
                </Text>
              </Flex>

              {win.preinteraction_code && (
                <Flex>
                  <Text bold size={18} capit text_box>
                    Codice:
                  </Text>
                  <Text capit style={{ marginLeft: '5px' }} text_box>
                    {win.preinteraction_code}
                  </Text>
                </Flex>
              )}
              {win?.file && (
                <a href={win?.file} target='_blank' rel='noopener noreferrer'>
                  <Text bold size={18} capit text_box>
                    Vedi scontrino
                  </Text>
                </a>
              )}
            </Flex>
          </Box>
        ))}
      </Fade>
    </>
  );
}

export default Plays;
