import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '../index';
import { Link } from 'react-router-dom';

const SmallLink = props => {
  const { link, clicked } = props;

  let smallLink = null;

  if (link) {
    smallLink = (
      <Link to={props.link}>
        <Typography as='p' type='smallLink' align={props.align} color='#ccc'>
          {props.children}
        </Typography>
      </Link>
    );
  } else {
    smallLink = (
      <Typography
        onClick={clicked}
        as='p'
        type='smallLink'
        align={props.align}
        underline
        fl
        color='#ccc'
      >
        {props.children}
      </Typography>
    );
  }

  return <React.Fragment>{smallLink}</React.Fragment>;
};

SmallLink.propTypes = {
  link: PropTypes.string,
  clicked: PropTypes.func,
};

export default SmallLink;
