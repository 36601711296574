import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Loader, Flex, Col, Text } from '../../components/Ui';
import { getSingleProduct } from '../../store/actions/products';
import { addToWishlist, removeFromWishlist, addToCart } from '../../store/actions/wishlist';

const Product = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [inWishlist, setInWishlist] = useState(false);

  const wishlist = useSelector(state => state.user.user.wishlist);
  const product = useSelector(state => state.product.product);
  const loading = useSelector(state => state.product.loading);

  useEffect(() => {
    if (wishlist && product) {
      const exist = wishlist.find(el => el.id === product.mProductId);
      if (exist) {
        setInWishlist(true);
      } else {
        setInWishlist(false);
      }
    }
  }, [wishlist, product]);

  useEffect(() => {
    if (id) {
      dispatch(getSingleProduct(id));
    }
  }, [dispatch, id]);

  const addToWishlistHandler = productId => {
    dispatch(addToWishlist(productId));
  };

  const removeFromWishlistHandler = productId => {
    dispatch(removeFromWishlist(productId));
  };

  return loading ? (
    <Loader show={loading} />
  ) : (
    <ProductWrapper>
      <Flex>
        <Col>
          <img src={product.mImg} alt='' />
          <div
            className='add-to-wishlist'
            onClick={() => {
              inWishlist
                ? removeFromWishlistHandler(product.mProductId)
                : addToWishlistHandler(product.mProductId);
            }}
          ></div>
        </Col>

        <Col>
          <div className='mb-10'>
            <Text>Punti</Text>
            <Text bold>{product.mProductPoints}</Text>
          </div>
          <div className='mb-10'>
            <Text>Categoria</Text>
            <Text bold>{product.mCategoria}</Text>
          </div>
          <div className='mb-10'>
            <Text>Codice prodotto</Text>
            <Text bold>{product.mCodiceEvasione}</Text>
          </div>

          <Text as='h1' className='mb-10'>
            {product.mProductBrand}
          </Text>

          <Text as='p' className='mb-10'>
            {product.mProductShortDescription}
          </Text>

          <Text as='p' className='mb-20'>
            {product.mProductDescription}
          </Text>

          <Text as='p' className='mb-20'>
            {`Tempo di consegna previsto ${product.mDelay} giorni`}
          </Text>

          <div>
            <Button onClick={() => history.goBack()}>Indietro</Button>
            <Button onClick={() => dispatch(addToCart(product.mProductId))}>Aggiungi</Button>
          </div>
        </Col>
      </Flex>
    </ProductWrapper>
  );
};

const ProductWrapper = styled.div``;

export default Product;
