import React from "react";
import styled from "styled-components";
import { Close } from "@styled-icons/material/Close";
import PropTypes from "prop-types";
import Fade from "../../Fade";


const BarContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
`;

const BarContent = styled.div`
    width: 100%;
    padding: 32px;
    background-color: #4dc274;
    background-color: ${(props) => handleColorType(props.type)};
    color: #fff;
`;

const CloseBar = styled.div`
  position: absolute;
  top: 27px;
  right: 27px;
  color: #fff;
  text-align: center;
  line-height: 25px;
  z-index: 999;
  cursor: pointer;
`;

const CloseIcon = styled(Close)`
    width: 25px;
    height: 25px;
`

const handleColorType = color => {
    switch (color) {
      case "success":
        return "#4dc274";
      case "error":
        return "#c80000";
      default:
        return "#333333";
    }
};


const NotificationBar = (props) => {
    const { show, type, children, close } = props;

    return (
        <BarContainer>
            <Fade show={show} direction="fromTop" >
            <BarContent type={type} >
                {children}
                <CloseBar onClick={close} >
                    <CloseIcon />
                </CloseBar>
            </BarContent>
        </Fade>
        </BarContainer>
    )
}

NotificationBar.propTypes = {
    close: PropTypes.func,
    children: PropTypes.any,
    show: PropTypes.bool
};

export default NotificationBar
