import React from "react";
import PropTypes from "prop-types";
import { GoogleLogin } from "react-google-login";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { Button, Loader } from "../../Ui";
import { getSlug } from "../../../utils/utils";

const Google = (props) => {
	const {
		oauthSendToken,
		isOauth,
		lastLocation,
		redirect,
		inModal,
		whereToGo,
		oauthInfo: {
			google: { clientid },
		},
	} = props;
	const [t] = useTranslation();

	const responseGoogle = (response) => {
		if (response) {
			const {
				Tt: { Bd, Du },
				accessToken,
				googleId,
			} = response;
			const data = {
				email: Du,
				name: Bd,
				userID: googleId,
			};

			oauthSendToken(accessToken, "google", data);
		}
	};

	const failureGoogle = (response) => {};

	let content = (
		<GoogleLogin
			clientId={clientid}
			buttonText="Accedi con con Google"
			onSuccess={responseGoogle}
			onFailure={failureGoogle}
			cookiePolicy={"single_host_origin"}
		/>
	);

	if (redirect) {
		let to = whereToGo;
		if (lastLocation !== null) {
			if (
				lastLocation.pathname.split("/")[1] === "activate" ||
				lastLocation.pathname.split("/")[1] === "reset-password" ||
				lastLocation.pathname.split("/")[1] === "auth"
			) {
				to = "/";
			} else {
				to = lastLocation.pathname;
			}
		}
		if (inModal) {
			to = `/${getSlug()}`;
		}
		return <Redirect to={to} />;
	} else {
		return content;
	}
};

Google.propTypes = {};

export default Google;
