import * as actionTypes from "./actionTypes";
import customaxios from "../../config/axios-refresh-token";

import { sendLogError } from "./error";

const getViewerStart = () => {
	return {
		type: actionTypes.GET_VIEWER_START,
	};
};

const getViewerSuccess = (viewer) => {
	return {
		type: actionTypes.GET_VIEWER_SUCCESS,
		viewer,
	};
};

const getViewerError = (error) => {
	return {
		type: actionTypes.GET_VIEWER_ERROR,
		error,
	};
};

export const getViewer = () => {
	let endpoint = `app/viewers`;
	return (dispatch) => {
		dispatch(getViewerStart());
		customaxios
			.get(endpoint)
			.then((result) => {
				dispatch(getViewerSuccess(result.data));
			})
			.catch((error) => {
				dispatch(getViewerError(error.response.status));
				if (error.response.status >= 500) {
					dispatch(sendLogError(endpoint, error));
				}
			});
	};
};
