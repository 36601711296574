import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button } from '../Ui';
import * as actionCreators from '../../store/actions';
import customaxios from '../../config/axios-refresh-token';

const WelfareCode = () => {
  const dispatch = useDispatch();

  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = e => {
    setCode(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    customaxios
      .post('/welfare/checkcode', { code })
      .then(res => {
        localStorage.setItem('token', res.data.token);
        dispatch(actionCreators.bootCms());
        dispatch(actionCreators.getUserInfo());
        setCode('');
        setError('');
        setSuccess(true);
      })
      .catch(err => {
        setError(err.response.data.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CodeContainer>
        <form className='code-content' onSubmit={handleSubmit}>
          <input type='text' value={code} onChange={handleChange} required />
          <Button loading={loading} active>
            Invia
          </Button>
        </form>
        {error && <p className='code-error'>{error}</p>}
        {success && <p className='code-success'>Codice inserito con successo</p>}
      </CodeContainer>
    </>
  );
};

const CodeContainer = styled.div`
  .code-content {
    width: 30%;
    min-width: 200px;
    margin: 0 auto;
    display: flex;

    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    input {
      margin-right: 15px;
      color: ${props => props.theme.colore_testo_campi};
      padding: 0 15px;
      background-color: ${props => props.theme.input.bg};
      border-radius: ${props => props.theme.border_radius_generale_campi};
      width: 100%;
      flex-grow: 1;
      height: 50px;
      font-size: 20px;
      font-weight: 700;
      outline: none;
      opacity: ${props => (props.readonly ? '0.3' : '1')};
      border: 2px solid ${props => props.theme.input.bg};

      &::placeholder {
        color: #47545d59;
      }

      @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }

  .code-error {
    color: red;
    margin-top: 20px;
    text-align: center;
  }

  .code-success {
    color: green;
    margin-top: 20px;
    text-align: center;
  }
`;

export default WelfareCode;
