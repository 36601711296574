import React from "react";
import PropTypes from "prop-types";
import FacebookLogin from "react-facebook-login";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import "./facebook.css";
import { Button, Loader } from "../../Ui";
import { getSlug } from "../../../utils/utils";
import "./facebook.css";

const Facebook = (props) => {
	const {
		oauthSendToken,
		isOauth,
		lastLocation,
		redirect,
		inModal,
		whereToGo,
		oauthInfo: {
			facebook: { clientid },
		},
	} = props;
	const [t] = useTranslation();

	const responseFacebook = (response) => {
		const { accessToken, graphDomain, email, userID, name } = response;
		const data = {
			email,
			userID,
			name,
		};
		if (response) {
			oauthSendToken(accessToken, graphDomain, data);
		}
	};

	let content = (
		<FacebookLogin
			appId={clientid}
			fields="name,email,picture"
			textButton={t("social.connectfb")}
			callback={responseFacebook}
			cssClass="facebook"
			icon="fa-facebook"
		/>
	);

	if (redirect) {
		let to = whereToGo;
		if (lastLocation !== null) {
			if (
				lastLocation.pathname.split("/")[1] === "activate" ||
				lastLocation.pathname.split("/")[1] === "reset-password" ||
				lastLocation.pathname.split("/")[1] === "auth"
			) {
				to = "/";
			} else {
				to = lastLocation.pathname;
			}
		}
		if (inModal) {
			to = `/${getSlug()}`;
		}
		return <Redirect to={to} />;
	} else {
		return content;
	}
};

Facebook.propTypes = {};

export default Facebook;
