import React from 'react';
import styled from 'styled-components';
import { Close as CloseIcon } from '@styled-icons/material';

const CloseContainer = styled(CloseIcon)`
  color: ${props => props.theme.primary};
`;

const Close = props => {
  const { clicked } = props;

  return <CloseContainer size={30} onClick={clicked} />;
};

export default Close;
