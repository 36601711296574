import React from 'react';
import { Wrapper } from '../../components/Ui';
import CmsBlocks from '../../components/CmsBlocks';
import { useSelector } from 'react-redux';

function Tutorial() {
  const tutorial = useSelector(state => state.cms.tutorial);

  return (
    <Wrapper>
      <CmsBlocks cms={tutorial} />
    </Wrapper>
  );
}

export default Tutorial;
