import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../../../theme/mixin';

const ImageContainer = styled.img`
  margin-bottom: ${props => (props.mBott ? '16px' : '0')};
  ${respondTo.sm`
        margin-bottom: ${props => (props.mBott ? '30px' : '0')};
    `}
  ${respondTo.md`
        margin-bottom: ${props => (props.mBott ? '30px' : '0')};
    `}
`;

export const Image = props => {
  const { src } = props;

  return <ImageContainer src={src} {...props} />;
};

export default Image;
