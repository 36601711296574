import React from 'react';
import { Col, Flex } from '../Ui';
import Soglia from './soglia/Soglia';
function Situation() {
  return (
    <Flex justify='space-between'>
      <Col width={49} padding={0} marginBottom={30}>
        <Soglia />
      </Col>
      <Col width={49} padding={0} marginBottom={30}>
        <Soglia />
      </Col>
      <Col width={49} padding={0} marginBottom={30}>
        <Soglia />
      </Col>
      <Col width={49} padding={0} marginBottom={30}>
        <Soglia />
      </Col>
    </Flex>
  );
}

export default Situation;
