import * as actionTypes from "../actions/actionTypes";

const initialState = {
	loading: true,
	error: null,
};

const bootAppReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.BOOT_APP_START:
			return { ...state, loading: true };
		case actionTypes.BOOT_APP_SUCCESS:
			return { ...state, loading: false };
		case actionTypes.BOOT_APP_ERROR:
			return { ...state, loading: false, error: action.error };
		default:
			return state;
	}
};

export default bootAppReducer;
