import React from 'react';
import PropTypes from 'prop-types';
import { Section, Wrapper, Loader } from '../Ui';
import BoxPromos from '../BoxPromos';
import ErrorReload from '../ErrorReload';
import { useTranslation } from 'react-i18next';
import TitleSection from '../TitleSection';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  min-height: 740px;
  display: flex;
  justify-content: center;
`;

const BoxContent = props => {
  const [t] = useTranslation();

  const {
    type,
    scream,
    description,
    promos,
    perrow,
    loading,
    error,
    clicked,
    istag
  } = props;

  let titlesection = null;

  switch (type) {
    case 'highlights':
      titlesection = (
        <TitleSection
          scream={scream ? scream : t('app.inevidencescream')}
          description={description ? description : t('app.inevidencedesc')}
        />
      );
      break;
    case 'allpromos':
      titlesection = (
        <TitleSection
          scream={scream ? scream : t('app.allpromoscream')}
          description={description ? description : t('app.allpromodesc')}
        />
      );
      break;
    default:
      titlesection = null;
      break;
  }

  return (
    <Section>
      <Wrapper>
        {titlesection}
        {loading ? (
          <LoaderContainer>
            <Loader show={true} />
          </LoaderContainer>
        ) : error >= 500 ? (
          <ErrorReload clicked={clicked} />
        ) : (
          <BoxPromos promos={promos} perrow={perrow} istag={istag} />
        )}
      </Wrapper>
    </Section>
  );
};

BoxContent.propTypes = {
  istag: PropTypes.bool,
  promos: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any
};

export default BoxContent;
