import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';

const Wrapper = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  padding: 0;
  background-color: ${({ theme }) => theme.bg_general};
  ${respondTo.sm`
 padding: 0 15px;
  `}
`;

export default Wrapper;
