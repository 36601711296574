import React from 'react';
import styled from 'styled-components';
import Product from '../product';
import { Text } from '../Ui';
import { respondTo } from '../../theme/mixin';
import { useSelector } from 'react-redux';
import CustomSlider from '../customSlider';
import { Link } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  border: 1px solid ${props => props.border || 'transparent'};
  border-radius: ${props => props.radius || props.theme.border_radius_generale_grande};
  padding: ${props => props.padding || '10px'};
  margin-bottom: ${props => props.marginBottom || '25px'};

  .slick-list {
    margin: 0 -5px;
  }
  .slick-slide > div {
    padding: 0 5px;
  }
  ${respondTo.sm`
     width:${({ width }) => (+width === 100 ? '100%' : 'calc(' + width + '% - 1%)')};
  `};
`;

function SliderProductContinue({
  data,
  setIsReviews,
  index,
  list,
  title,
  width,
  notif,
  setpuntiAbbastanza,
}) {
  const queryApp = useSelector(state => state.app.appQuery);

  const style = notif && JSON.parse(notif?.style);
  const arrRespo =
    +width === 100
      ? [
          {
            breakpoint: 1224,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: false,
              dots: false,
            },
          },
          {
            breakpoint: 1180,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: false,
              dots: false,
            },
          },
          {
            breakpoint: 920,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
        ]
      : [
          {
            breakpoint: 1900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: false,
              dots: false,
            },
          },
        ];
  var settings = {
    slidesToShow: +width === 100 ? (list.length <= 4 ? list.length : 4) : 2,
    slidesToScroll: 1,
    responsive: arrRespo,
    arrows: true,
  };

  return (
    <Container
      width={width}
      bgColor={notif?.background_color}
      border={style?.border}
      marginBottom={style?.marginBottom}
      padding={style?.padding}
      radius={style?.radius}
    >
      {queryApp ? (
        <a href='/catalog'>
          <Text align='center' bold type='title' margin='0 0 16px 0'>
            {title}
          </Text>
        </a>
      ) : (
        <Link
          to={{
            pathname: '/catalog',
            state: { auto_filter: notif?.auto_filter, auto_filter_value: notif?.auto_filter_value },
          }}
        >
          <Text align='center' bold type='title' margin='0 0 16px 0'>
            {title}
          </Text>
        </Link>
      )}

      <CustomSlider settings={settings}>
        {list?.map((item, index) => {
          return (
            <Product
              key={item.id}
              product={item}
              catalogId={item.catalog_id}
              setIsReviews={setIsReviews}
              inSlider
              border
              index={index}
              setpuntiAbbastanza={setpuntiAbbastanza}
              inApp={queryApp}
            />
          );
        })}
      </CustomSlider>
    </Container>
  );
}

export default SliderProductContinue;
