import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Delete } from '@styled-icons/material-twotone';
import { Button, Text } from '../../../Ui';
import { useSelector } from 'react-redux';

const VideoPreviewContainer = styled.div`
  padding: 40px;
  overflow: hidden;
  width: 100%;
  background-color: #e4eaef;
  border-radius: 10px;
  position: relative;
  display: ${props => (props.show ? 'inline-block' : 'none')};

  video {
    width: 100%;
    object-fit: cover;
  }
`;

const VideoPreview = styled.div`
  display: flex;
  justify-content: center;
`;

const DeleteContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #a3aaae;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadButton = styled(Button)`
  display: ${props => (props.show ? 'inline-block' : 'none')};
  border: 1px solid
    ${props =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.bordo_bottone};
`;

const Video = props => {
  const {
    setVideo,
    setFormValid,
    formSetSelectedFiles,
    resetFormHandler,
    deleteFileHandler,
    resetVideo,
    video_size,
    video_length,
    video_extension,
    setValidationVideo,
  } = props;
  const upRef = useRef(null);

  const [videoPreviewUrl2, setVideoPreviewUrl2] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState([]);

  const fileUploadHandler2 = event => {
    event.preventDefault();
    setErrors([]);

    const file = event.target.files[0];
    const video_extensions = video_extension?.split(',')?.map(item => 'video/' + item);

    if (file !== undefined) {
      setSelectedFile({
        name: event.target.name,
        file: event.target.files[0],
      });
      const isExtensionTrue = video_extensions.includes(file.type);
      if (!isExtensionTrue) {
        setErrors(prev => [...prev, 'Formato non supportato, usa: ' + video_extension]);
      }
      if (video_size * 1024 * 1024 < file.size) {
        setErrors(prev => [
          ...prev,
          'Il video caricato supera la dimensione massima consentita: ' + video_size + ' MB',
        ]);
      }

      setFormValid(event);

      const reader = new FileReader();
      reader.onloadend = () => {
        if (file.type !== 'application/pdf') {
          setVideoPreviewUrl2(reader.result);
          var video = document.createElement('video');
          video.src = URL.createObjectURL(file);

          video.onloadedmetadata = function () {
            if (+video_length < video.duration) {
              setErrors(prev => [
                ...prev,
                'Il video supera la lunghezza massima consentita: ' + video_length + ' secondi',
              ]);
            }
          };
          setVideo(true);
        }
      };
      reader.readAsDataURL(file);
      event.target.value = null;
    } else {
      setVideoPreviewUrl2(null);
    }
  };

  const deleteFileHandler2 = () => {
    setVideoPreviewUrl2(null);
    setVideo(false);
    setSelectedFile(null);
    deleteFileHandler();
  };

  useEffect(() => {
    if (selectedFile) {
      formSetSelectedFiles(selectedFile);
    }
  }, [selectedFile]);

  useEffect(() => {
    setValidationVideo(errors.length);
  }, [errors]);

  useEffect(() => {
    if (resetVideo) {
      setVideoPreviewUrl2(null);
    }
  }, [resetVideo]);

  return (
    <>
      <VideoPreviewContainer show={videoPreviewUrl2}>
        <VideoPreview>
          {videoPreviewUrl2 && (
            <Text bold primary>
              {selectedFile.file.name}
            </Text>
          )}
        </VideoPreview>
        <ul style={{ marginTop: '10px' }}>
          {errors.map(err => (
            <>
              <Text as='li' color='red' size={14}>
                &#9679; {' ' + err}
              </Text>
            </>
          ))}
        </ul>

        <DeleteContainer onClick={deleteFileHandler2}>
          <Delete size={23} />
        </DeleteContainer>
      </VideoPreviewContainer>

      <input
        style={{ display: 'none' }}
        ref={upRef}
        {...props.elementConfig}
        onChange={fileUploadHandler2}
      />
      <UploadButton
        {...props}
        fullwidth
        upper
        onClick={() => upRef.current.click()}
        show={!videoPreviewUrl2}
        active
      >
        {props.elementConfig.placeholder}
      </UploadButton>
    </>
  );
};

export default Video;
