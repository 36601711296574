import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StepContainer = styled.div`
  background-color: #fff;
  border-radius: 1.5625rem;
  position: relative;
  height: 50px;
  line-height: 50px;
  //box-shadow: 2px 2px 6px 0px rgba(201, 201, 201, 1);
  /* width: 116px; */
`;
const StepSpan = styled.span`
  font-size: 12px;
  margin-left: 8px;
  display: block;
  line-height: 50px;
`;
const StepNumber = styled.div`
  //background-color: ${props => props.theme.bg_bottoni};
  /* color: ${props => props.theme.default_text_color}; */
  color: #000;
  border-radius: 1.5625rem;
  font-weight: bold;
  /* position: absolute; */
  top: 0;
  right: 0;
  height: 100%;
  width: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 14px;
`;

const Steps = props => {
  const { current, total } = props;
  const [t] = useTranslation();

  return (
    <StepContainer>
      {/*  <StepSpan>{t('games.question')}</StepSpan> */}
      <StepNumber>{`${current}/${total}`}</StepNumber>
    </StepContainer>
  );
};

export default Steps;
