import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PauseCircle, PlayCircle } from "styled-icons/boxicons-regular";
import { respondTo } from "../../../../theme/mixin";
import Vimeo from "@u-wave/react-vimeo";
import YouTube from "@u-wave/react-youtube";

const VideoContainer = styled.div`
	max-width: 100%;
	margin: auto;
	position: relative;
	overflow: hidden;
	& video {
		width: 100%;
		height: auto;
	}
	${respondTo.sm`
    max-width: 98%;
  
  `}
`;

const VideoContainerYt = styled.div`
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	/* padding-top: 25px; */
	height: 0;
	& iframe {
		margin: 0 !important;
		position: absolute !important;
		top: 0 !important;
		left: 0 !important;
		width: 100% !important;
		height: 100% !important;
	}
`;

const Control = styled.div`
	cursor: pointer;
	margin: 0 6px;
	width: 26px;
	${respondTo.sm`
        width: 40px;
    `}
`;

const ToolControls = styled.div`
	position: absolute;
	bottom: 4px;
	width: 100%;
	padding: 12px;
	z-index: 9999;
	background-image: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0),
		rgba(0, 0, 0, 0.7)
	);
	color: white;
	display: flex;
`;

const Video = (props) => {
	// html5
	const [showTool, setShowTool] = useState(true);
	const [isPlaying, setIsPlaying] = useState(false);
	// for vimeo and youtube
	const [pauseVideo, setPauseVideo] = useState(true);

	const {
		src,
		pause,
		option: { embed, src: srcVideo, cover },
	} = props;

	const videoRef = useRef(null);
	const toolRef = useRef(null);
	const videoContRef = useRef(null);

	useEffect(() => {
		if (embed) {
			if (embed === "html5") {
				videoContRef.current.addEventListener("mouseenter", showToolControls);
				videoContRef.current.addEventListener("mouseleave", hideToolControls);
			}
		}
		return () => {
			if (embed) {
				if (embed === "html5") {
					videoContRef.current.removeEventListener(
						"mouseenter",
						showToolControls
					);
					videoContRef.current.removeEventListener(
						"mouseleave",
						hideToolControls
					);
				}
			}
		};
	}, [isPlaying]);

	useEffect(() => {
		if (embed) {
			if (embed === "html5") {
				if (pause) {
					videoRef.current.pause();
				}
			} else {
				if (pause) {
					setPauseVideo(true);
				}
			}
		}
	}, [pause]);

	const showToolControls = () => {
		setShowTool(true);
	};

	const hideToolControls = () => {
		if (isPlaying) {
			setShowTool(false);
		}
	};

	const handlePlay = () => {
		setIsPlaying(true);
		videoRef.current.play();
	};

	const handlePause = () => {
		setIsPlaying(false);
		videoRef.current.pause();
	};

	let videocustom = null;

	const opts = {
		height: "auto",
		width: "100%",
		playerVars: {
			autoplay: 0,
			rel: 0,
		},
	};

	const onPlay = (event) => {
		setPauseVideo(false);
	};

	switch (embed) {
		case "vimeo":
			videocustom = (
				<VideoContainerYt>
					<Vimeo
						video={src ? src : srcVideo}
						onPlay={onPlay}
						paused={pauseVideo}
					/>
				</VideoContainerYt>
			);
			break;
		case "youtube":
			videocustom = (
				<VideoContainerYt>
					<YouTube
						video={src ? src : srcVideo}
						onPlaying={onPlay}
						paused={pauseVideo}
					/>
				</VideoContainerYt>
			);
			break;
		case "html5":
			videocustom = (
				<VideoContainer ref={videoContRef}>
					{showTool ? (
						<ToolControls ref={toolRef}>
							<Control onClick={handlePlay}>
								<PlayCircle />
							</Control>
							<Control onClick={handlePause}>
								<PauseCircle />
							</Control>
						</ToolControls>
					) : null}
					<video ref={videoRef} controls={false} poster={cover}>
						<source src={src ? src : srcVideo} type="video/mp4" />
					</video>
				</VideoContainer>
			);
			break;
	}

	return videocustom;
};

Video.propTypes = {
	src: PropTypes.string,
	pause: PropTypes.bool,
};

export default Video;
