import React from 'react'
import PropTypes from 'prop-types'

const Page404 = props => {

    const {
        message
    } = props;

    return (
        <div>
            <h1>{message}</h1>            
        </div>
    )
}

Page404.propTypes = {

}

export default Page404
