import * as actionTypes from '../actions/actionTypes';

const initialState = {
  token: null,
  userId: null,
  loading: true,
  error: null,
  errorsignin: null,
  loadingsignin: false,
  logout: false,
  loadingRecovery: false,
  recovery: false,
  recoverySuccessMessage: '',
  errorRecovery: null,
  loadingReset: true,
  reset: false,
  resetSuccessMessage: '',
  errorReset: null,
  loadingChangePsw: false,
  changePsw: false,
  errorChangePsw: null,
  redirect: false,
  loadingSignup: false,
  signup: false,
  signupSuccessMessage: '',
  errorSignup: null,
  isOauth: false,
  loadingOauthSendToken: true,
  errorOauthSendoToken: null,
  remember: 1,
  show_auth_form: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        token: action.token,
        userId: action.userId,
        loading: false,
        error: null,
        logout: false,
      };
    case actionTypes.AUTH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.UPDATE_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case actionTypes.SIGNIN_START:
      return {
        ...state,
        loadingsignin: true,
      };
    case actionTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        errorsignin: null,
        loadingsignin: false,
        token: action.token,
        userId: action.userId,
      };
    case actionTypes.SIGNIN_ERROR:
      return {
        ...state,
        loadingsignin: false,
        errorsignin: action.error,
      };
    case actionTypes.SIGNIN_CLEAR_ERROR:
      return {
        ...state,
        errorsignin: null,
        error: null,
      };
    case actionTypes.SIGNUP_START:
      return {
        ...state,
        loadingSignup: true,
      };
    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        loadingSignup: false,
        signup: true,
        errorSignup: null,
        signupSuccessMessage: action.message,
      };
    case actionTypes.SIGNUP_ERROR:
      return {
        ...state,
        loadingSignup: false,
        errorSignup: action.error,
      };
    case actionTypes.SIGNUP_CLEAR_ERROR:
      return {
        ...state,
        loadingSignup: false,
        signup: false,
        errorSignup: null,
      };
    case actionTypes.LOGOUT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        token: null,
        logout: true,
      };
    case actionTypes.LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.RECOVERY_PASSWORD_START:
      return {
        ...state,
        loadingRecovery: true,
      };
    case actionTypes.RECOVERY_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingRecovery: false,
        recovery: true,
        errorRecovery: null,
        recoverySuccessMessage: action.message,
      };
    case actionTypes.RECOVERY_PASSWORD_ERROR:
      return {
        ...state,
        loadingRecovery: false,
        errorRecovery: action.error,
      };
    case actionTypes.RECOVERY_PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        errorRecovery: null,
        recovery: false,
      };
    case actionTypes.RESET_PASSWORD_START:
      return {
        ...state,
        loadingReset: true,
      };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingReset: false,
        reset: true,
      };
    case actionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loadingReset: false,
        errorReset: action.error,
      };
    case actionTypes.RESET_PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        loadingReset: false,
        errorReset: null,
      };
    case actionTypes.RESET_CHANGE_PASSWORD_START:
      return {
        ...state,
        loadingChangePsw: true,
      };
    case actionTypes.RESET_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingChangePsw: false,
        changePsw: true,
        resetSuccessMessage: action.message,
      };
    case actionTypes.RESET_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loadingChangePsw: false,
        errorChangePsw: action.error,
      };
    case actionTypes.RESET_CHANGE_PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        loadingChangePsw: false,
        errorChangePsw: null,
      };
    case actionTypes.SET_REDIRECT:
      return {
        ...state,
        redirect: true,
      };
    case actionTypes.SET_REDIRECT_FALSE:
      return {
        ...state,
        redirect: false,
      };
    case actionTypes.OAUTH_SEND_TOKEN_START:
      return {
        ...state,
        loadingOauthSendToken: true,
        isOauth: true,
      };
    case actionTypes.OAUTH_SEND_TOKEN_SUCCESS:
      return {
        ...state,
        loadingOauthSendToken: false,
        token: action.token,
        isOauth: false,
      };
    case actionTypes.OAUTH_SEND_TOKEN_ERROR:
      return {
        ...state,
        loadingOauthSendToken: false,
        error: action.error,
        isOauth: false,
        errorOauthSendoToken: action.error,
      };
    case actionTypes.REMEMBER_START:
      return {
        ...state,
        remember: action.val,
      };
    case actionTypes.SHOW_AUTH_FORM:
      return {
        ...state,
        show_auth_form: action.bool,
      };

    default:
      return state;
  }
};

export default authReducer;
